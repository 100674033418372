import React, { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import AvailableIngredient from "../../domain/AvailableIngredient";
import { useOrganization } from "../organizations/OrganizationProvider";
import AutoSuggest from "../utils/AutoSuggest";
import { LightButton } from "../utils/Button";
import Card from "../utils/Card";
import Checkbox from "../utils/Checkbox";
import Form from "../utils/Form";
import { getNameForLocale } from "../utils/getNameForLocale";
import { Table, TableColumn } from "../utils/Table";
import { ArrowRight, AttentionTriangle } from "../utils/Vectors";
import * as RecipeIngredientLinking from "./RecipeIngredientLinking";
import { IngredientNameStatus } from "./UploadRecipesPage";

const decideLaterMessage = (
  <FormattedMessage
    id="components/recipes/UploadRecipesPage:ingredientMatcher/decideLater"
    defaultMessage="<medium>Update later</medium>"
    values={{
      medium: (chunks: React.ReactNode) => (
        <span className="medium-font">{chunks}</span>
      ),
    }}
  />
);

const decideLaterOrWaitMessage = (
  <FormattedMessage
    id="components/recipes/UploadRecipesPage:ingredientMatcher/decideLaterOrWaitUntilAvailable"
    defaultMessage="<medium>Update later</medium> or <medium>wait until available</medium>"
    values={{
      medium: (chunks: React.ReactNode) => (
        <span className="medium-font">{chunks}</span>
      ),
    }}
  />
);

const foodstepsIngredientColumnHeading = (
  <FormattedMessage
    id="components/recipes/UploadRecipesPage:ingredientMatcher/foodstepsIngredient"
    defaultMessage="Foodsteps Ingredient"
  />
);

const originalIngredientColumnHeading = (
  <FormattedMessage
    id="components/recipes/UploadRecipesPage:ingredientMatcher/ingredientName"
    defaultMessage="Original Ingredient"
  />
);

const updateMatchColumnHeading = (
  <FormattedMessage
    id="components/recipes/UploadRecipesPage:ingredientMatcher/updateMatch"
    defaultMessage="Update"
  />
);

interface SubmitButtonLabel {
  loadingLabel: React.ReactNode;
  submitLabel: React.ReactNode;
}

interface IngredientMatcherProps {
  availableIngredients: Array<AvailableIngredient>;
  canRequestImpactAnalysis: boolean;
  onSubmit: () => Promise<void>;
  ingredientNameStatuses: Array<IngredientNameStatus>;
  onCancel?: () => void;
  onIsAcceptedChange: (
    status: IngredientNameStatus,
    isAccepted: boolean
  ) => void;
  onSelectAlternativeChange: ({
    status,
    alternativeIngredientName,
    alternativeIngredientFoodClassId,
  }: {
    status: IngredientNameStatus;
    alternativeIngredientName: string;
    alternativeIngredientFoodClassId: number | null;
  }) => void;
  submitButtonLabel: SubmitButtonLabel;
}

export default function IngredientMatcher(props: IngredientMatcherProps) {
  const {
    availableIngredients,
    canRequestImpactAnalysis,
    onCancel,
    onSubmit,
    ingredientNameStatuses,
    onIsAcceptedChange,
    onSelectAlternativeChange,
    submitButtonLabel,
  } = props;
  return (
    <div className="w-75">
      <p>
        <FormattedMessage
          id="components/recipes/UploadedRecipesPage:ingredientMatchingInstructions/couldNotFindMatches"
          defaultMessage="We couldn't find some of your ingredients by their exact name."
        />
      </p>
      <p>
        {canRequestImpactAnalysis ? (
          <FormattedMessage
            id="components/recipes/UploadedRecipesPage:ingredientMatchingInstructions/closestMatchExplanation"
            defaultMessage="Update each of these to a <medium>Foodsteps Ingredient</medium> from our database. Alternatively, <medium>update later</medium> or <medium>wait for Foodsteps to make it available in the database</medium> by unchecking the box."
            values={{
              medium: (chunks: React.ReactNode) => (
                <span className="medium-font">{chunks}</span>
              ),
            }}
          />
        ) : (
          <FormattedMessage
            id="components/recipes/UploadedRecipesPage:ingredientMatchingInstructions/closestMatchExplanationCanRequestImpactAnalysis"
            defaultMessage="Update each of these to a <medium>Foodsteps Ingredient</medium> from our database, or <medium>update later</medium> by unchecking the box."
            values={{
              medium: (chunks: React.ReactNode) => (
                <span className="medium-font">{chunks}</span>
              ),
            }}
          />
        )}
      </p>
      <div className="form-group pb-4">
        <Form onSubmit={onSubmit}>
          <Form.SubmitButton
            loadingLabel={submitButtonLabel.loadingLabel}
            submitLabel={submitButtonLabel.submitLabel}
          />
          {onCancel !== undefined ? (
            <Form.SecondaryButton className="ml-3" onClick={onCancel}>
              <FormattedMessage
                id="components/recipes/UploadRecipesPage:cancel"
                defaultMessage="Cancel"
              />
            </Form.SecondaryButton>
          ) : null}
          <Form.ErrorAlert className="mt-3" />
        </Form>
      </div>
      <div className="flex-fit">
        <IngredientNameStatusTable
          availableIngredients={availableIngredients}
          canRequestImpactAnalysis={canRequestImpactAnalysis}
          ingredientNameStatuses={ingredientNameStatuses}
          onIsAcceptedChange={onIsAcceptedChange}
          onSelectAlternativeChange={onSelectAlternativeChange}
        />
      </div>
    </div>
  );
}

interface IngredientNameStatusTableProps {
  availableIngredients: Array<AvailableIngredient>;
  canRequestImpactAnalysis: boolean;
  ingredientNameStatuses: Array<IngredientNameStatus>;
  onIsAcceptedChange: (
    status: IngredientNameStatus,
    isAccepted: boolean
  ) => void;
  onSelectAlternativeChange: ({
    status,
    alternativeIngredientName,
    alternativeIngredientFoodClassId,
  }: {
    status: IngredientNameStatus;
    alternativeIngredientName: string;
    alternativeIngredientFoodClassId: number | null;
  }) => void;
}

function IngredientNameStatusTable(props: IngredientNameStatusTableProps) {
  const {
    availableIngredients,
    canRequestImpactAnalysis,
    ingredientNameStatuses,
    onIsAcceptedChange,
    onSelectAlternativeChange,
  } = props;
  const intl = useIntl();
  const [organization] = useOrganization();

  const availableIngredientsByName = useMemo(
    () =>
      new Map(
        availableIngredients.map((ingredient) => [
          getNameForLocale(ingredient, organization.localeForFoodClasses),
          ingredient,
        ])
      ),
    [availableIngredients, organization.localeForFoodClasses]
  );

  const inexactStatuses = ingredientNameStatuses.filter(
    (status) => !status.isExact
  );

  const columns: Array<TableColumn<IngredientNameStatus>> = [
    {
      className: "pt-2",
      key: "originalIngredient",
      label: <div className="pb-2">{originalIngredientColumnHeading}</div>,
      renderCell: (status) => (
        <div className="justify-content-between d-flex">
          <div className="medium-font">{status.ingredientName}</div>
          <div className="pl-4">
            {status.isAccepted ? (
              <ArrowRight width={20} />
            ) : (
              <AttentionTriangle width={20} />
            )}
          </div>
        </div>
      ),
      verticalAlign: "middle",
    },
    {
      className: "pt-2 ingredient-column",
      key: "foodstepsIngredient",
      label: <div className="pb-2">{foodstepsIngredientColumnHeading}</div>,
      renderCell: (status) =>
        status.isAccepted ? (
          <div className="row">
            <div className="w-100 col-10 pr-0">
              <AutoSuggest
                allSuggestions={availableIngredients}
                forceSelection={true}
                inputError={status.hasError}
                getSuggestionValue={(availableIngredient) =>
                  getNameForLocale(
                    availableIngredient,
                    organization.localeForFoodClasses
                  )
                }
                onChange={(alternativeIngredientName) =>
                  onSelectAlternativeChange({
                    status,
                    alternativeIngredientName,
                    alternativeIngredientFoodClassId:
                      availableIngredientsByName.get(alternativeIngredientName)
                        ?.id ?? null,
                  })
                }
                placeholder={intl.formatMessage({
                  id: "components/recipes/UploadRecipesPage:foodstepsIngredientAutoSuggestPlaceholder",
                  defaultMessage: "Search for an ingredient",
                })}
                render={(props) => (
                  <div className="input-group">
                    <input
                      id="recipesUploadFoodstepsIngredientInput"
                      {...props}
                    />
                    <div className="input-group-append">
                      <span
                        className="input-group-text text-middle"
                        style={{ lineHeight: "1rem" }}
                      >
                        <RecipeIngredientLinking.Icon
                          ingredient={{
                            foodClassId: status.selectedAlternativeFoodClassId,
                          }}
                        />
                      </span>
                    </div>
                  </div>
                )}
                value={status.selectedAlternativeName ?? ""}
              />
            </div>
            <div className="col-2 px-3 pt-2">
              {status.selectedAlternativeName !== status.foodClassName ? (
                <LightButton
                  onClick={() =>
                    onSelectAlternativeChange({
                      status,
                      // foodClassName is only null if the ingredient status has been matched
                      // to a subrecipe
                      // in which case it should not be accessible here
                      alternativeIngredientName: status.foodClassName!,
                      alternativeIngredientFoodClassId: status.foodClassId,
                    })
                  }
                >
                  {intl.formatMessage({
                    id: "components/recipes/UploadRecipesPage:foodstepsIngredientAutoSuggestReset",
                    defaultMessage: "Reset",
                  })}
                </LightButton>
              ) : null}
            </div>
          </div>
        ) : (
          <div className="decide-later-cell">
            {canRequestImpactAnalysis
              ? decideLaterOrWaitMessage
              : decideLaterMessage}
          </div>
        ),
      verticalAlign: "middle",
    },
    {
      className: "pt-2",
      key: "updateMatch",
      label: <div className="pb-2">{updateMatchColumnHeading}</div>,
      renderCell: (status) => (
        <Checkbox
          checked={status.isAccepted}
          onChange={(isAccepted) => onIsAcceptedChange(status, isAccepted)}
        />
      ),
      align: "center",
      verticalAlign: "middle",
    },
  ];

  return (
    <Card>
      <Table<IngredientNameStatus>
        columns={columns}
        rowClassName="status-table-row"
        rowKey={(status) => status.ingredientName}
        rows={inexactStatuses}
      />
    </Card>
  );
}
