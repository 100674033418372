import gql from "graphql-tag";
import { createContext, useContext, useEffect, useState } from "react";
import { useCallbackOne } from "use-memo-one";

import { useGraphQL } from "../components/graphql/GraphQLProvider";
import StatusDisplay from "../components/StatusDisplay";
import { organizationFeaturesFragment } from "../services/useOrganizationFeatures";
import { useSession } from "../sessions";
import * as statuses from "../util/statuses";
import * as useIsAdmin from "./useIsAdmin";
import {
  useUserInfo_Organization as Organization,
  useUserInfo_ViewerQuery as ViewerQuery,
  useUserInfo_UserInfo as UserInfo,
} from "./useUserInfo.graphql";

export type { Organization };

const UserInfoContext = createContext<
  [UserInfo, () => Promise<void>] | null | undefined
>(undefined);

interface UserInfoProviderProps {
  children: React.ReactNode;
}

export function UserInfoProvider(props: UserInfoProviderProps) {
  const { children } = props;

  const session = useSession();
  if (session.userId === null) {
    return (
      <UserInfoContext.Provider value={null}>
        {children}
      </UserInfoContext.Provider>
    );
  } else {
    return <LoggedInUserInfoProvider>{children}</LoggedInUserInfoProvider>;
  }
}

export function LoggedInUserInfoProvider(props: UserInfoProviderProps) {
  const { children } = props;
  const graphQL = useGraphQL();

  const [userInfoStatus, setUserInfoStatus] = useState<
    statuses.Status<UserInfo>
  >(statuses.loading);

  const loadUserInfo = useCallbackOne(async () => {
    try {
      const viewerResponse = await graphQL.fetch<ViewerQuery, null>({
        query: viewerQuery(),
        variables: null,
      });
      const viewer = viewerResponse.viewer;
      setUserInfoStatus(statuses.success(viewer));
    } catch (error) {
      setUserInfoStatus(statuses.error(error));
    }
  }, [graphQL]);

  useEffect(() => {
    loadUserInfo();
  }, [loadUserInfo]);

  return (
    <StatusDisplay status={userInfoStatus}>
      {(userInfo) => (
        <UserInfoContext.Provider value={[userInfo, loadUserInfo]}>
          {children}
        </UserInfoContext.Provider>
      )}
    </StatusDisplay>
  );
}

export default function useUserInfo(): [UserInfo, () => Promise<void>] {
  const result = useContext(UserInfoContext);

  if (result === undefined) {
    throw new Error("UserInfoProvider not present in component tree");
  } else if (result === null) {
    throw new Error("not logged in");
  } else {
    return result;
  }
}

export function useUserInfoOrNull(): UserInfo | null {
  const result = useContext(UserInfoContext);
  if (result === undefined) {
    throw new Error("UserInfoProvider not present in component tree");
  } else {
    return result === null ? null : result[0];
  }
}

export function viewerQuery() {
  return gql`
    query useUserInfo_ViewerQuery {
      viewer {
        ...useUserInfo_UserInfo
      }
    }

    fragment useUserInfo_UserInfo on User {
      email
      featureFlags
      firstName
      id
      isReadonly
      lastName
      locale
      organizationMemberships {
        ...useIsAdmin_OrganizationMembership
        organization {
          ...useUserInfo_Organization
        }
      }
      username
    }

    fragment useUserInfo_Organization on Organization {
      defaultCurrency
      defaultTaxCountry
      id
      name
      viewerHasRecipeCollectionPermissionAdd
      viewerHasRecipePermissionAdd
      productTier
      recipeCountLimit
      signUpOverrideId
      projectOptions {
        endMileType {
          name
        }
        endOfLifeType
        postPreparationStorageScenario {
          name
        }
      }
      parentId
      localeForFoodClasses
      ...useOrganizationFeatures_Organization
    }

    ${organizationFeaturesFragment()}
    ${useIsAdmin.fragments.organizationMembership}
  `;
}
