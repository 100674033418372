import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { RecipePageName } from "../../tracking";
import DownloadLabelsModal, {
  RecipeFilterWithExclude,
} from "../labels/DownloadLabelsModal";
import { CarbonLabelsIcon, SelectedCarbonLabelsIcon } from "../utils/Vectors";
import { RecipesDropdownItem } from "./RecipesDropdown";

export default function useExportCarbonLabelsDropdownItem({
  closeDropdown = () => {},
  recipeFilter,
  pageName,
}: {
  closeDropdown?: () => void;
  recipeFilter: RecipeFilterWithExclude;
  pageName: RecipePageName;
}): RecipesDropdownItem {
  const [showModal, setShowModal] = useState(false);

  const exportAllRecipes = !recipeFilter.ids || recipeFilter.ids.length === 0;

  const handleClick = () => {
    setShowModal(true);
    closeDropdown();
  };

  return {
    action: {
      type: "button",
      onClick: handleClick,
    },
    Icon: exportAllRecipes ? CarbonLabelsIcon : SelectedCarbonLabelsIcon,
    render: (Component) => (
      <>
        <Component>
          {exportAllRecipes ? (
            <FormattedMessage
              id="components/recipes/ExportCarbonLabelsDropdownItem:label"
              defaultMessage="Export all carbon labels"
            />
          ) : (
            <FormattedMessage
              id="components/recipes/ExportSelectedCarbonLabelsDropdownItem:label"
              defaultMessage="Carbon labels"
            />
          )}
        </Component>
        <DownloadLabelsModal
          onHide={() => setShowModal(false)}
          page={pageName}
          recipeFilter={recipeFilter}
          show={showModal}
        />
      </>
    ),
  };
}
