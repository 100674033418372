import gql from "graphql-tag";
import React, { ReactNode, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useHistory } from "react-router-dom";

import { RecipeFilter } from "../../__generated__/globalTypes";
import useCollections from "../../data-store/useCollections";
import { useTags } from "../../services/useOrganizationFeatures";
import { useTracking } from "../../tracking";
import UserVisibleError from "../../util/UserVisibleError";
import { useGraphQL } from "../graphql/GraphQLProvider";
import useMutation from "../graphql/useMutation";
import { RecipeFilterWithExclude } from "../labels/DownloadLabelsModal";
import { useOrganizationId } from "../organizations/OrganizationProvider";
import { usePages } from "../pages";
import ProductLimitModal from "../sharing/ProductLimitModal";
import StatusDisplay from "../StatusDisplay";
import AddTagModal from "../tags/AddTagModal";
import TagMultiSelect, {
  TagMultiSelectOption,
  TagMultiSelectOptionType,
} from "../tags/TagMultiSelect";
import ActionModal from "../utils/ActionModal";
import { PrimaryButton, SecondaryButton } from "../utils/Button";
import Form from "../utils/Form";
import RadioButtons from "../utils/RadioButtons";
import { MultiSelect } from "../utils/Select";
import TooltipOverlay from "../utils/TooltipOverlay";
import useId from "../utils/useId";
import { SaveCopy } from "../utils/Vectors";
import "./CopyToButton.css";
import {
  CopyToButton_CopyRecipes,
  CopyToButton_CopyRecipesVariables,
  CopyToButton_Collection,
  CopyToButton_ExistingRecipeAndPackagingComponentNamesInOrganizationQueryVariables,
  CopyToButton_ExistingRecipeAndPackagingComponentNamesInOrganizationQuery,
  CopyToButton_RecipesQuery,
  CopyToButton_RecipesQueryVariables,
  CopyToButton_Recipe,
} from "./CopyToButton.graphql";
import useRecipeLabel from "./useRecipeLabel";

type ModalState =
  | { type: "hidden" }
  | { type: "intoCollection"; recipesToCopy: Array<CopyToButton_Recipe> }
  | {
      type: "existingRecipeNames";
      existingRecipeNames: Array<string>;
      existingPackagingComponentNames: Array<string>;
      selectedCollectionIds: Array<number>;
    }
  | {
      type: "existingPackagingComponentNames";
      existingPackagingComponentNames: Array<string>;
      selectedCollectionIds: Array<number>;
      updateExistingRecipes: boolean;
    }
  | {
      type: "productLimitExceeded";
    }
  | { type: "newTag" };

interface CopyToButtonProps {
  control?: (onClick: () => void) => ReactNode;
  intoCollectionsModalSubTitle: ReactNode;
  intoCollectionsModalTitle: ReactNode;
  recipeFilter: RecipeFilterWithExclude;
  setToastState: (state: CopySuccessToastState) => void;
}

export function CopyToButton(props: CopyToButtonProps) {
  const {
    control,
    recipeFilter: { excludedIds, ...recipeFilter },
    intoCollectionsModalSubTitle,
    intoCollectionsModalTitle,
    setToastState,
  } = props;

  const [organizationId] = useOrganizationId();
  const recipeLabel = useRecipeLabel();
  const [copyRecipes] = useMutation<
    CopyToButton_CopyRecipes,
    CopyToButton_CopyRecipesVariables
  >(copyRecipesMutation());
  const graphQL = useGraphQL();
  const history = useHistory();
  const pages = usePages();
  const hasTagsFeature = useTags();
  const { trackTagCreated } = useTracking();

  const [modalState, setModalState] = useState<ModalState>({ type: "hidden" });
  const [selectedCollectionIds, setSelectedCollectionIds] = useState<
    Array<number>
  >([]);

  const { collectionsStatus, addCollection } = useCollections();

  const recipesToCopy = useRecipesToCopy(recipeFilter, excludedIds);

  const buildToastMessage = (recipes: Array<CopyToButton_Recipe>) => {
    const text = (
      <FormattedMessage
        id="components/recipes/CopyToButton:MultipleRecipeCopyToButton/toastMessage/singleRecipe"
        defaultMessage="View {recipeLabel}"
        values={{
          recipeLabel:
            recipes.length === 1
              ? recipeLabel.singularLowercase
              : recipeLabel.pluralLowercase,
        }}
      />
    );
    const url =
      recipes.length === 1
        ? pages.Recipe(hasTagsFeature).url({ id: recipes[0].id })
        : pages.Recipes(hasTagsFeature).url;

    return <Link to={url}>{text}</Link>;
  };

  const buildToastTitle = (recipes: Array<CopyToButton_Recipe>) => {
    if (recipes.length === 1) {
      return (
        <FormattedMessage
          id="components/recipes/CopyToButton:MultipleRecipeCopyToButton/toastTitle/singleRecipe"
          defaultMessage="{recipeName} has been added to your account."
          values={{
            recipeName: recipes[0].name,
          }}
        />
      );
    } else {
      return (
        <FormattedMessage
          id="components/recipes/CopyToButton:MultipleRecipeCopyToButton/toastTitle/multipleRecipes"
          defaultMessage="{count} {recipeLabel} have been added to your account."
          values={{
            count: recipes.length,
            recipeLabel: recipeLabel.pluralLowercase,
          }}
        />
      );
    }
  };

  const doCopyCallback = async (
    collectionIds: number[],
    replacePackagingComponents: boolean,
    replaceRecipes: boolean
  ) => {
    const recipes = await recipesToCopy;
    try {
      const response = await copyRecipes({
        variables: {
          input: {
            recipeIds: recipes.map((recipe) => recipe.id),
            shallowCopyPackaging: false,
            shallowCopyRecipes: false,
            toCollectionIds: collectionIds,
            updateExistingComponents: replacePackagingComponents,
            updateExistingRecipes: replaceRecipes,
            toOrganizationId: organizationId,
          },
        },
      });
      setToastState({
        message: buildToastMessage(response.copyRecipes.recipes),
        title: buildToastTitle(response.copyRecipes.recipes),
      });
      setModalState({ type: "hidden" });
    } catch (e: any) {
      if (e instanceof Error && e.message === "Recipe count limit reached.") {
        setModalState({ type: "productLimitExceeded" });
      }
    }
  };

  const copyRecipesHandler = async () => {
    const recipes = await recipesToCopy;
    setModalState({ type: "intoCollection", recipesToCopy: recipes });
  };

  const noRecipesSelected =
    excludedIds === undefined && recipeFilter.ids?.length === 0;

  async function fetchExistingRecipeAndPackagingComponentNames(
    collectionIds: Array<number>
  ) {
    const recipes = await recipesToCopy;
    const response = await graphQL.fetch<
      CopyToButton_ExistingRecipeAndPackagingComponentNamesInOrganizationQuery,
      CopyToButton_ExistingRecipeAndPackagingComponentNamesInOrganizationQueryVariables
    >({
      query: existingRecipeAndPackagingComponentNamesInOrganizationQuery,
      variables: {
        collectionIds,
        recipeIds: recipes.map((recipe) => recipe.id),
        organizationId,
      },
    });
    return response;
  }

  async function handleIntoCollectionButtonSubmit(
    selectedCollectionIds: Array<number>
  ) {
    const {
      existingRecipeNamesInOrganizationCollections,
      existingPackagingComponentNamesInOrganization,
    } = await fetchExistingRecipeAndPackagingComponentNames(
      selectedCollectionIds
    );

    if (existingRecipeNamesInOrganizationCollections.length > 0) {
      setModalState({
        type: "existingRecipeNames",
        existingRecipeNames: existingRecipeNamesInOrganizationCollections,
        existingPackagingComponentNames:
          existingPackagingComponentNamesInOrganization,
        selectedCollectionIds,
      });
    } else {
      if (existingPackagingComponentNamesInOrganization.length > 0) {
        setModalState({
          type: "existingPackagingComponentNames",
          existingPackagingComponentNames:
            existingPackagingComponentNamesInOrganization,
          selectedCollectionIds,
          updateExistingRecipes: false,
        });
      } else {
        await doCopyCallback(selectedCollectionIds, false, false);
      }
    }
    setSelectedCollectionIds([]);
  }

  const handleExistingRecipeNameModalSubmit = async (
    updateExistingRecipes: boolean
  ) => {
    if (modalState.type !== "existingRecipeNames") {
      throw Error("Invalid state");
    }
    if (modalState.existingPackagingComponentNames.length > 0) {
      setModalState({
        type: "existingPackagingComponentNames",
        existingPackagingComponentNames:
          modalState.existingPackagingComponentNames,
        selectedCollectionIds: modalState.selectedCollectionIds,
        updateExistingRecipes,
      });
    } else {
      await doCopyCallback(
        modalState.selectedCollectionIds,
        false,
        updateExistingRecipes
      );
    }
  };

  const handleExistingPackagingComponentNamesModalSubmit = async (
    updateExistingPackagingComponents: boolean
  ) => {
    if (modalState.type !== "existingPackagingComponentNames") {
      throw Error("Invalid state");
    }
    await doCopyCallback(
      modalState.selectedCollectionIds,
      updateExistingPackagingComponents,
      modalState.updateExistingRecipes
    );
  };

  const handleHide = () => {
    setSelectedCollectionIds([]);
    setModalState({ type: "hidden" });
  };

  const handleContact = () => {
    history.push(pages.ContactUs.url);
  };

  const handleNewTagClicked = () => {
    setModalState({ type: "newTag" });
  };

  const handleNewTagCreated = async (newTag: { id: number; name: string }) => {
    const recipes = await recipesToCopy;
    setModalState({ type: "intoCollection", recipesToCopy: recipes });
    setSelectedCollectionIds([...selectedCollectionIds, newTag.id]);
    trackTagCreated({
      tagId: newTag.id,
      tagName: newTag.name,
      workflow: "Copy Shared Products",
    });
  };

  const handleCancelAddTag = async () => {
    const recipes = await recipesToCopy;
    setModalState({ type: "intoCollection", recipesToCopy: recipes });
  };

  const defaultControl = (onClick: () => void) => (
    <PrimaryButton
      icon={<SaveCopy width="16px" height="16px" />}
      onClick={onClick}
    >
      {copyToText}
    </PrimaryButton>
  );

  const buttonControl = control
    ? control(copyRecipesHandler)
    : defaultControl(copyRecipesHandler);

  return (
    <div className="copy-to-button">
      {noRecipesSelected ? (
        <TooltipOverlay
          id="components/recipes/CopyToButton:toolTip"
          placement="top"
          overlay={
            <FormattedMessage
              id="components/recipes/CopyToButton:toolTipText"
              defaultMessage="Select {recipeLabel} to copy."
              values={{
                recipeLabel: recipeLabel.pluralLowercase,
              }}
            />
          }
        >
          {buttonControl}
        </TooltipOverlay>
      ) : (
        buttonControl
      )}
      {hasTagsFeature && modalState.type === "intoCollection" ? (
        <StatusDisplay status={collectionsStatus}>
          {(collections) => (
            <TagsModal
              allCollections={collections}
              onHide={handleHide}
              onNewTagClicked={handleNewTagClicked}
              onSubmit={handleIntoCollectionButtonSubmit}
              recipesToCopy={modalState.recipesToCopy}
              selectedCollectionIds={selectedCollectionIds}
              setSelectedCollectionIds={setSelectedCollectionIds}
              show
            />
          )}
        </StatusDisplay>
      ) : (
        <IntoCollectionsModal
          handleSubmit={handleIntoCollectionButtonSubmit}
          onHide={handleHide}
          show={modalState.type === "intoCollection"}
          subTitle={intoCollectionsModalSubTitle}
          title={intoCollectionsModalTitle}
        />
      )}
      {modalState.type === "existingRecipeNames" && (
        <HandleExistingNamesModal
          onSubmit={handleExistingRecipeNameModalSubmit}
          names={modalState.existingRecipeNames}
          objectName={recipeLabel.singularLowercase}
          onHide={handleHide}
        />
      )}
      {modalState.type === "existingPackagingComponentNames" && (
        <HandleExistingNamesModal
          onSubmit={handleExistingPackagingComponentNamesModalSubmit}
          names={modalState.existingPackagingComponentNames}
          objectName={
            <FormattedMessage
              defaultMessage="packaging component"
              id="components/recipes/CopyToButton:packagingComponent"
            />
          }
          onHide={handleHide}
        />
      )}
      <ProductLimitModal
        onContact={handleContact}
        onClose={handleHide}
        show={modalState.type === "productLimitExceeded"}
      />
      <AddTagModal
        addTag={addCollection}
        show={modalState.type === "newTag"}
        onCancel={handleCancelAddTag}
        onComplete={handleNewTagCreated}
      />
    </div>
  );
}

export interface CopySuccessToastState {
  message: ReactNode;
  title: ReactNode;
}

const copyToText = (
  <FormattedMessage
    id="components/recipes/CopyToButton:name"
    defaultMessage="Copy to"
  />
);

interface SingleRecipeCopyToButtonProps {
  recipe: {
    id: number;
    name: string;
  };
  setToastState: (state: CopySuccessToastState) => void;
}

export function SingleRecipeCopyToButton(props: SingleRecipeCopyToButtonProps) {
  const { recipe, setToastState } = props;

  const recipeLabel = useRecipeLabel();

  return (
    <CopyToButton
      recipeFilter={{ ids: [recipe.id] }}
      intoCollectionsModalTitle={
        <FormattedMessage
          id="components/recipes/CopyToButton:modalTitleSingleRecipe"
          defaultMessage="Copy {name}"
          values={{ name: recipe.name }}
        />
      }
      intoCollectionsModalSubTitle={
        <FormattedMessage
          id="components/recipes/CopyToButton:modalSubtitleSingleRecipe"
          defaultMessage="Which collections would you like to copy this {recipeLabel} to?"
          values={{ recipeLabel: recipeLabel.singularLowercase }}
        />
      }
      setToastState={setToastState}
    />
  );
}

interface MultipleRecipeCopyToButtonProps {
  control?: (onClick: () => void) => ReactNode;
  recipeFilter: RecipeFilterWithExclude;
  setToastState: (state: CopySuccessToastState) => void;
}

export function MultipleRecipeCopyToButton(
  props: MultipleRecipeCopyToButtonProps
) {
  const { control, recipeFilter, setToastState } = props;
  const recipeLabel = useRecipeLabel();

  return (
    <CopyToButton
      control={control}
      recipeFilter={recipeFilter}
      intoCollectionsModalTitle={
        <FormattedMessage
          id="components/recipes/CopyToButton:modalTitleMultipleRecipes"
          defaultMessage="Copy selected {recipeLabel}"
          values={{
            recipeLabel: recipeLabel.pluralLowercase,
          }}
        />
      }
      intoCollectionsModalSubTitle={
        <FormattedMessage
          id="components/recipes/CopyToButton:modalSubtitleMultipleRecipes"
          defaultMessage="Which collections would you like to copy these {recipeLabel} to?"
          values={{
            recipeLabel: recipeLabel.pluralLowercase,
          }}
        />
      }
      setToastState={setToastState}
    />
  );
}

const cancelText = (
  <FormattedMessage
    id="components/recipes/CopyToButton:modal/cancel"
    defaultMessage="Cancel"
  />
);

const copyText = (
  <FormattedMessage
    id="components/recipes/CopyToButton:modal/copy"
    defaultMessage="Copy"
  />
);

const copyingText = (
  <FormattedMessage
    id="components/recipes/CopyToButton:modal/copying"
    defaultMessage="Copying"
  />
);

interface TagsModalProps {
  allCollections: Array<CopyToButton_Collection>;
  onHide: () => void;
  onNewTagClicked: () => void;
  onSubmit: (collectionIds: Array<number>) => Promise<void>;
  recipesToCopy: Array<CopyToButton_Recipe>;
  show: boolean;
  selectedCollectionIds: Array<number>;
  setSelectedCollectionIds: (collectionIds: Array<number>) => void;
}

function TagsModal(props: TagsModalProps) {
  const {
    allCollections,
    onHide,
    onNewTagClicked,
    onSubmit,
    recipesToCopy,
    selectedCollectionIds,
    setSelectedCollectionIds,
    show,
  } = props;

  const recipeLabel = useRecipeLabel();

  const collectionToOption = (
    collection: CopyToButton_Collection
  ): TagMultiSelectOption => {
    return {
      type: TagMultiSelectOptionType.SIMPLE,
      id: collection.id,
      name: collection.name,
    };
  };

  const handleChange = (ids: ReadonlyArray<number>) =>
    setSelectedCollectionIds(ids.map((id) => id));

  const title = () => {
    if (recipesToCopy.length === 1) {
      return (
        <FormattedMessage
          id="components/recipes/CopyToButton:TagsModal/title/singleRecipe"
          defaultMessage="Copy {recipeName}"
          values={{ recipeName: recipesToCopy[0].name }}
        />
      );
    } else {
      return (
        <FormattedMessage
          id="components/recipes/CopyToButton:TagsModal/title/multipleRecipes"
          defaultMessage="Copy {count} {recipeLabel}"
          values={{
            count: recipesToCopy.length,
            recipeLabel: recipeLabel.pluralLowercase,
          }}
        />
      );
    }
  };

  return (
    <ActionModal show={show} title={title()}>
      <Form onSubmit={() => onSubmit(selectedCollectionIds)}>
        <ActionModal.Body>
          <div className="CopyToButton_TagModal_Instructions">
            <FormattedMessage
              id="components/recipes/CopyToButton:modal/addTags"
              defaultMessage="Add tags?"
            />
          </div>
          <TagMultiSelect
            options={allCollections.map(collectionToOption)}
            onNewTagClicked={onNewTagClicked}
            onChange={handleChange}
            placeholder={
              <FormattedMessage
                id="components/recipes/CopyToButton:modal/tagMultiSelectPlaceholder"
                defaultMessage="Search tags"
              />
            }
            selectedIds={selectedCollectionIds}
          />
        </ActionModal.Body>
        <ActionModal.Footer>
          <div>
            <Form.ErrorAlert className="mt-4" />
          </div>
          <SecondaryButton
            className="CopyToButton_TagModal_CancelButton"
            onClick={() => onHide()}
          >
            {cancelText}
          </SecondaryButton>
          <Form.SubmitButton
            submitLabel={copyText}
            loadingLabel={copyingText}
          />
        </ActionModal.Footer>
      </Form>
    </ActionModal>
  );
}

interface IntoCollectionsModalProps {
  handleSubmit: (collectionIds: Array<number>) => Promise<void>;
  onHide: () => void;
  show: boolean;
  subTitle: ReactNode;
  title: ReactNode;
}

export function IntoCollectionsModal(props: IntoCollectionsModalProps) {
  const { handleSubmit, onHide, show, subTitle, title } = props;

  const { collectionsStatus } = useCollections();
  const id = useId();
  const intl = useIntl();

  const [selectedCollections, setSelectedCollections] = useState<
    CopyToButton_Collection[]
  >([]);

  return (
    <>
      <ActionModal
        show={show}
        title={<div className="copy-to-button-modal-title">{title}</div>}
      >
        <div className="copy-to-button-modal">
          <Form
            onSubmit={() =>
              handleSubmit(
                selectedCollections.map((collection) => collection.id)
              )
            }
          >
            <ActionModal.Body>
              <div className="medium-font">{subTitle}</div>
              <StatusDisplay status={collectionsStatus}>
                {(collections) => (
                  <MultiSelect<CopyToButton_Collection>
                    className="multi-select-collections"
                    id={id}
                    onChange={(value) => setSelectedCollections([...value])}
                    optionKey={(collection) => collection.id.toString()}
                    options={collections}
                    placeholder={intl.formatMessage({
                      id: "components/recipes/CopyToButton:collectionsDefaultPlaceholder",
                      defaultMessage: "None",
                    })}
                    renderOption={(collection) => collection.name}
                    value={selectedCollections}
                  />
                )}
              </StatusDisplay>
            </ActionModal.Body>
            <ActionModal.Footer>
              <div>
                <Form.ErrorAlert className="mt-4" />
              </div>
              <div className="footer-buttons">
                <Form.SubmitButton
                  submitLabel={copyText}
                  loadingLabel={copyingText}
                />
                <SecondaryButton onClick={() => onHide()}>
                  {cancelText}
                </SecondaryButton>
              </div>
            </ActionModal.Footer>
          </Form>
        </div>
      </ActionModal>
    </>
  );
}

IntoCollectionsModal.fragments = {
  collection: gql`
    fragment CopyToButton_Collection on RecipeCollection {
      id
      name
    }
  `,
};

interface HandleExistingNamesModalProps {
  objectName: React.ReactNode;
  onHide: () => void;
  onSubmit: (replace: boolean) => Promise<void>;
  names: Array<string>;
}

function HandleExistingNamesModal(props: HandleExistingNamesModalProps) {
  const { names, objectName, onHide, onSubmit } = props;

  const intl = useIntl();

  const [replace, setReplace] = useState<boolean | null>(null);
  const [replaceHoverValue, setReplaceHoverValue] = useState<boolean | null>(
    null
  );

  const handleSubmit = async (replace: boolean | null) => {
    if (replace === null) {
      throw Error(
        "A value for replace or keep both must be selected before submitting."
      );
    }
    const duplicateNames = new Map();
    for (const name of names) {
      duplicateNames.set(name, duplicateNames.get(name) + 1 || 1);
    }
    const namesWithMultipleDuplcates = [...duplicateNames]
      .filter(([name, count]) => count > 1)
      .map(([name]) => name);
    if (replace && namesWithMultipleDuplcates.length === 1) {
      throw new UserVisibleError(
        "multiple duplicates caused ambiguous replacement",
        intl.formatMessage(
          {
            id: "components/recipes/CopyToButton:HandleExistingNameModal/replaceErrorSingle",
            defaultMessage:
              "There is more than one existing {objectName} called {name}, so we cannot tell which one should be replaced.",
          },
          { name: `"${namesWithMultipleDuplcates[0]}"`, objectName }
        )
      );
    } else if (replace && namesWithMultipleDuplcates.length > 1) {
      throw new UserVisibleError(
        "multiple duplicates caused ambiguous replacement",
        intl.formatMessage(
          {
            id: "components/recipes/CopyToButton:HandleExistingNameModal/replaceErrorPlural",
            defaultMessage:
              "There is more than one existing {objectName} with each of these names: {names}. So we cannot tell which ones should be replaced.",
          },
          { names: `"${namesWithMultipleDuplcates.join('","')}"`, objectName }
        )
      );
    }
    await onSubmit(replace);
  };

  const renderOptionTitle = (replace: boolean) => {
    if (replace) {
      return intl.formatMessage({
        id: "components/recipes/CopyToButton:HandleExistingNameModal/replace",
        defaultMessage: "Replace",
      });
    } else {
      return intl.formatMessage({
        id: "components/recipes/CopyToButton:HandleExistingNameModal/keepBoth",
        defaultMessage: "Keep both",
      });
    }
  };

  const renderOptionDescription = (replace: boolean) => {
    if (replace) {
      return intl.formatMessage(
        {
          id: "components/recipes/CopyToButton:HandleExistingNameModal/replaceDescription",
          defaultMessage:
            "Existing {objectName}s with the same name will be overwritten.",
        },
        { objectName }
      );
    } else {
      return intl.formatMessage(
        {
          id: "components/recipes/CopyToButton:HandleExistingNameModal/keepBothDescription",
          defaultMessage:
            "New {objectName}s will be created and existing versions also kept.",
        },
        { objectName }
      );
    }
  };

  const renderOption = (replace: boolean) => {
    return (
      <>
        <div>{renderOptionTitle(replace)}</div>
        <div className="text-muted">{renderOptionDescription(replace)}</div>
      </>
    );
  };

  const renderTitle = (): React.ReactNode => {
    if (names.length === 0) {
      throw Error("Cannot have an empty list of names.");
    }
    if (names.length === 1) {
      return (
        <b>
          <FormattedMessage
            defaultMessage="You already have a {objectName} called {name}."
            id="components/recipes/CopyToButton:HandleExistingNamesModal/titleSingle"
            values={{
              objectName,
              name: names[0],
            }}
          />
        </b>
      );
    } else {
      return (
        <b>
          <FormattedMessage
            defaultMessage="You already have {numObjects} {objectName}s with the same names."
            id="components/recipes/CopyToButton:HandleExistingNamesModal/titleMultiple"
            values={{
              objectName,
              numObjects: names.length,
            }}
          />
        </b>
      );
    }
  };

  return (
    <ActionModal
      show={true}
      title={<div className="copy-to-button-modal-title">{renderTitle()}</div>}
    >
      <div className="copy-to-button-modal">
        <Form onSubmit={() => handleSubmit(replace)}>
          <ActionModal.Body>
            <p className="medium-font mb-0">
              <FormattedMessage
                id="components/recipes/CopyToButton:HandleExistingNamesModal/subtitle"
                defaultMessage="What would you like to do?"
              />
            </p>
            <RadioButtons<boolean>
              buttonStyle="square"
              className="mb-3"
              hoverValue={replaceHoverValue}
              onChange={setReplace}
              optionKey={renderOptionTitle}
              options={[true, false]}
              renderOptionLabel={renderOption}
              setHoverLabel={setReplaceHoverValue}
              value={replace}
            />
          </ActionModal.Body>
          <ActionModal.Footer>
            <div>
              <Form.ErrorAlert className="mt-4" />
            </div>
            <div className="footer-buttons mt-3">
              <SecondaryButton onClick={() => onHide()}>
                <FormattedMessage
                  id="components/recipes/CopyToButton:HandleExistingNamesModal/cancel"
                  defaultMessage="Cancel"
                />
              </SecondaryButton>
              <Form.SubmitButton
                disabled={replace === null}
                submitLabel={
                  <FormattedMessage
                    defaultMessage="Continue"
                    id="components/recipes/CopyToButton:HandleExistingNamesModal/continue"
                  />
                }
                loadingLabel={
                  <FormattedMessage
                    defaultMessage="Loading"
                    id="components/recipes/CopyToButton:HandleExistingNamesModal/loading"
                  />
                }
              />
            </div>
          </ActionModal.Footer>
        </Form>
      </div>
    </ActionModal>
  );
}

async function* useRecipePages(
  recipeFilter: RecipeFilter
): AsyncGenerator<Array<CopyToButton_Recipe>> {
  const graphQL = useGraphQL();

  let after: string | null = null;

  while (true) {
    const response: CopyToButton_RecipesQuery = await graphQL.fetch<
      CopyToButton_RecipesQuery,
      CopyToButton_RecipesQueryVariables
    >({
      query: recipesQuery,
      variables: {
        after,
        first: 1000,
        recipeFilter,
      },
    });

    yield response.recipes.edges.map((edge) => edge.node);

    if (response.recipes.pageInfo.hasNextPage) {
      after = response.recipes.pageInfo.endCursor;
    } else {
      return;
    }
  }
}

async function useRecipesToCopy(
  recipeFilter: RecipeFilter,
  excludedIds: Array<number> | undefined
): Promise<CopyToButton_Recipe[]> {
  const recipes = [];
  for await (const recipePage of useRecipePages(recipeFilter)) {
    const includedRecipes = recipePage.filter(
      (recipe) => excludedIds === undefined || !excludedIds?.includes(recipe.id)
    );
    recipes.push(...includedRecipes);
  }
  return recipes;
}

export function copyRecipesMutation() {
  return gql`
    mutation CopyToButton_CopyRecipes($input: CopyRecipesInput!) {
      copyRecipes(input: $input) {
        recipes {
          id
          name
        }
      }
    }
  `;
}

const existingRecipeAndPackagingComponentNamesInOrganizationQuery = gql`
  query CopyToButton_ExistingRecipeAndPackagingComponentNamesInOrganizationQuery(
    $collectionIds: [Int!]!
    $organizationId: UUID!
    $recipeIds: [Int!]!
  ) {
    existingPackagingComponentNamesInOrganization(
      organizationId: $organizationId
      recipeIds: $recipeIds
    )
    existingRecipeNamesInOrganizationCollections(
      collectionIds: $collectionIds
      organizationId: $organizationId
      recipeIds: $recipeIds
    )
  }
`;

const recipesQuery = gql`
  query CopyToButton_RecipesQuery(
    $after: String
    $first: Int!
    $recipeFilter: RecipeFilter!
  ) {
    recipes(after: $after, first: $first, filter: $recipeFilter) {
      edges {
        node {
          ...CopyToButton_Recipe
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }

  fragment CopyToButton_Recipe on Recipe {
    id
    name
  }
`;
