import classNames from "classnames";
import { gql } from "graphql-tag";
import JSZip from "jszip";
import React, { ReactElement, useState } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";

import "./DownloadLabelsModal.css";
import "../../App.scss";
import { EffectType, RecipeFilter } from "../../__generated__/globalTypes";
import { useFoodManufacturerOrganization } from "../../services/useOrganizationFeatures";
import { LabelsDownloadPage, useTracking } from "../../tracking";
import assertNever from "../../util/assertNever";
import slugifyString from "../../util/slugifyString";
import { useGraphQL } from "../graphql/GraphQLProvider";
import useQuery from "../graphql/useQuery";
import PackagingSwitch from "../packaging/PackagingSwitch";
import StatusDisplay from "../StatusDisplay";
import { SecondaryButton } from "../utils/Button";
import Card from "../utils/Card";
import Form from "../utils/Form";
import RadioButtons, { RadioButtonElements } from "../utils/RadioButtons";
import Generate from "../utils/Vectors/Generate";
import CarbonLabel, {
  CarbonLabelSizeOption,
  CarbonLabelType,
  generateCarbonLabelPngBase64,
  LabelColourSetting,
  SizeDependentCarbonLabelType,
} from "./carbon-label/CarbonLabel";
import CarbonLabelSizeSelect from "./CarbonLabelSizeSelect";
import {
  DownloadLabelsModal_Recipe,
  DownloadLabelsModal_Recipe as Recipe,
  DownloadLabelsModal_RecipesQuery as RecipesQuery,
  DownloadLabelsModal_RecipesQueryVariables as RecipesQueryVariables,
} from "./DownloadLabelsModal.graphql";
import {
  DownloadLabelsModal_RecipeCountQuery as RecipeCountQuery,
  DownloadLabelsModal_RecipeCountQueryVariables as RecipeCountQueryVariables,
} from "./DownloadLabelsModal.graphql";
import {
  effectType,
  impactMagnitude,
  labelSizeToDisplayedSize,
  labelTypeToDisplayedType,
} from "./helperFunctions";

export interface RecipeFilterWithExclude extends RecipeFilter {
  excludedIds?: number[];
}

interface LabelSizes {
  selectedDescriptiveRatingLabelSize: CarbonLabelSizeOption;
  selectedRatingScaleLabelSize: CarbonLabelSizeOption;
  selectedLetterRatingLabelSize: CarbonLabelSizeOption;
}

interface DownloadLabelsModalProps {
  onHide: () => void;
  page: LabelsDownloadPage;
  recipeFilter: RecipeFilterWithExclude;
  show: boolean;
}

export default function DownloadLabelsModal(props: DownloadLabelsModalProps) {
  const { page, onHide, recipeFilter, show } = props;

  const [chosenLabel, setChosenLabel] = useState<CarbonLabelType | null>(null);
  const [labelColourSetting, setLabelColourSetting] =
    useState<LabelColourSetting>("colour");
  const [hoverLabel, setHoverLabel] = useState<CarbonLabelType | null>(null);
  const [selectedLabelSizes, setSelectedLabelSizes] = useState<LabelSizes>({
    selectedDescriptiveRatingLabelSize: "small",
    selectedLetterRatingLabelSize: "small",
    selectedRatingScaleLabelSize: "small",
  });

  const intl = useIntl();

  const monochrome = labelColourSetting === "monochrome";

  const labelCardsData: {
    label: ReactElement;
    labelType: CarbonLabelType;
    title: string;
  }[] = [
    {
      label: (
        <CarbonLabel
          impactRating="MEDIUM"
          monochrome={monochrome}
          size="small"
          type="letterRating"
          width={46}
        />
      ),
      labelType: "letterRating",
      title: intl.formatMessage({
        id: "components/labels/DownloadLabelsModal:letterRatingLabelTitle",
        defaultMessage: "Letter Rating",
      }),
    },
    {
      label: (
        <CarbonLabel
          impactRating="MEDIUM"
          monochrome={monochrome}
          size="small"
          type="descriptiveRating"
          width={103}
        />
      ),
      labelType: "descriptiveRating",
      title: intl.formatMessage({
        id: "components/labels/DownloadLabelsModal:descriptiveRatingLabelTitle",
        defaultMessage: "Descriptive Rating",
      }),
    },
    {
      label: (
        <CarbonLabel
          impactRating="MEDIUM"
          monochrome={monochrome}
          size="small"
          type="ratingScale"
          width={84}
        />
      ),
      labelType: "ratingScale",
      title: intl.formatMessage({
        id: "components/labels/DownloadLabelsModal:ratingScaleLabelTitle",
        defaultMessage: "Rating Scale",
      }),
    },
    {
      label: (
        <CarbonLabel
          impactRating="MEDIUM"
          monochrome={monochrome}
          type="titledRatingScale"
          width={96.5}
        />
      ),
      labelType: "titledRatingScale",
      title: intl.formatMessage({
        id: "components/labels/DownloadLabelsModal:titledRatingScaleLabelTitle",
        defaultMessage: "Titled Rating Scale",
      }),
    },
    {
      label: (
        <CarbonLabel
          impactRating="MEDIUM"
          monochrome={monochrome}
          type="descriptiveRatingScale"
          width={63.3}
        />
      ),
      labelType: "descriptiveRatingScale",
      title: intl.formatMessage({
        id: "components/labels/DownloadLabelsModal:descriptiveRatingScaleLabelTitle",
        defaultMessage: "Descriptive Rating Scale",
      }),
    },
    {
      label: (
        <CarbonLabel
          effectType={EffectType.GHG_PER_KG}
          impactMagnitude={4.6}
          impactRating="MEDIUM"
          monochrome={monochrome}
          type="descriptiveImpactAndScale"
          width={64}
        />
      ),
      labelType: "descriptiveImpactAndScale",
      title: intl.formatMessage({
        id: "components/labels/DownloadLabelsModal:descriptiveImpactAndScaleTitle",
        defaultMessage: "Descriptive Impact & Scale",
      }),
    },
  ];

  const radioButtons = RadioButtonElements<CarbonLabelType>({
    inline: true,
    onChange: setChosenLabel,
    optionKey: (option) => option,
    options: labelCardsData.map((cardData) => cardData.labelType),
    renderOptionLabel: () => null,
    hoverValue: hoverLabel,
    value: chosenLabel,
  });

  const selectedSizeDependentLabelSize = (
    labelType: SizeDependentCarbonLabelType
  ): CarbonLabelSizeOption => {
    if (labelType === "descriptiveRating") {
      return selectedLabelSizes.selectedDescriptiveRatingLabelSize;
    } else if (labelType === "letterRating") {
      return selectedLabelSizes.selectedLetterRatingLabelSize;
    } else if (labelType === "ratingScale") {
      return selectedLabelSizes.selectedRatingScaleLabelSize;
    } else {
      assertNever(labelType, "Unsupported SizeDependentCarbonLabelType");
    }
  };

  const labelSize = (
    labelType: CarbonLabelType | null
  ): CarbonLabelSizeOption | null => {
    if (
      labelType === "descriptiveRating" ||
      labelType === "letterRating" ||
      labelType === "ratingScale"
    ) {
      return selectedSizeDependentLabelSize(labelType);
    } else if (
      labelType === "descriptiveImpactAndScale" ||
      labelType === "descriptiveRatingScale" ||
      labelType === "titledRatingScale"
    ) {
      return "small";
    } else if (labelType === null) {
      return null;
    } else {
      assertNever(labelType, "Unsupported label type");
    }
  };

  const handleLabelSizeSelect = (
    labelType: SizeDependentCarbonLabelType,
    size: CarbonLabelSizeOption
  ) => {
    if (labelType === "descriptiveRating") {
      setSelectedLabelSizes({
        ...selectedLabelSizes,
        selectedDescriptiveRatingLabelSize: size,
      });
    } else if (labelType === "letterRating") {
      setSelectedLabelSizes({
        ...selectedLabelSizes,
        selectedLetterRatingLabelSize: size,
      });
    } else if (labelType === "ratingScale") {
      setSelectedLabelSizes({
        ...selectedLabelSizes,
        selectedRatingScaleLabelSize: size,
      });
    } else {
      assertNever(labelType, "Unexpected label type");
    }
  };

  const labelSizeSelect = (labelType: CarbonLabelType) => {
    if (
      labelType === "descriptiveRating" ||
      labelType === "letterRating" ||
      labelType === "ratingScale"
    ) {
      return (
        <CarbonLabelSizeSelect
          labelType={labelType}
          selectedItem={selectedSizeDependentLabelSize(labelType)}
          onSelect={handleLabelSizeSelect}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <Modal
      centered
      className="download-modal"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={onHide}
      show={show}
      size="xl"
    >
      <Modal.Header className="p-0" closeButton={false}>
        <h4 className="mb-0">
          <FormattedMessage
            id="components/labels/DownloadLabelsModal:exportCarbonLabels"
            defaultMessage="Export Carbon Labels"
          />
        </h4>
      </Modal.Header>
      <Modal.Body>
        <div style={{ flexBasis: "60%" }}>
          <div
            className="pb-3"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <h6 className="semi-bold-font mb-0">
              <FormattedMessage
                id="components/labels/DownloadLabelsModal:labelDesigns"
                defaultMessage="Label designs"
              />
            </h6>
            {/* we trust the external domain and so want to supply a referrer
                because it can be useful for analytics */}
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a
              className="ml-auto mb-0"
              href="https://foodsteps.circle.so/c/product-tutorials/choosing-a-label-type-and-size"
              target="_blank"
              rel="noopener"
            >
              <FormattedMessage
                id="components/labels/DownloadLabelsModal:helpChoosingLabel"
                defaultMessage="Which label should I choose?"
              />
            </a>
          </div>
          <div className="card-grid">
            {labelCardsData.map((cardData, index) => (
              <LabelCard
                hover={hoverLabel === cardData.title}
                key={`label-card-${cardData.labelType}`}
                label={cardData.label}
                radioButton={radioButtons[index]}
                selected={chosenLabel === cardData.labelType}
                setHover={(hover: boolean) =>
                  setHoverLabel(hover ? cardData.labelType : null)
                }
                setSelected={() => setChosenLabel(cardData.labelType)}
                title={cardData.title}
                labelSizeSelect={labelSizeSelect(cardData.labelType)}
              />
            ))}
          </div>
        </div>
        <div style={{ flexBasis: "40%" }}>
          <DownloadLabelForm
            chosenLabelSize={labelSize(chosenLabel)}
            chosenLabelType={chosenLabel}
            labelColourSetting={labelColourSetting}
            onCancel={onHide}
            page={page}
            recipeFilter={recipeFilter}
            setLabelColourSetting={setLabelColourSetting}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

interface LabelCardProps {
  hover: boolean;
  label: ReactElement;
  radioButton: ReactElement;
  labelSizeSelect: JSX.Element | null;
  selected: boolean;
  setHover: (hover: boolean) => void;
  setSelected: () => void;
  title: string;
}

function LabelCard(props: LabelCardProps) {
  const {
    label,
    labelSizeSelect,
    radioButton,
    selected,
    setHover,
    setSelected,
    title,
  } = props;

  return (
    <Card
      className="label-card"
      selected={selected}
      setHover={setHover}
      setSelected={setSelected}
      shadow={false}
    >
      <div className="position-relative">
        <div className="position-absolute" style={{ top: 0, left: 0 }}>
          {radioButton}
        </div>
      </div>
      <div className="d-flex h-100 justify-content-center align-items-center pb-3">
        {label}
      </div>
      <p
        className={classNames(
          "small medium-font card-title text-center",
          labelSizeSelect === null ? "mb-0" : "mb-2"
        )}
      >
        {title}
      </p>
      {labelSizeSelect}
    </Card>
  );
}

interface DownloadLabelFormProps {
  chosenLabelType: CarbonLabelType | null;
  chosenLabelSize: CarbonLabelSizeOption | null;
  labelColourSetting: LabelColourSetting;
  onCancel: () => void;
  page: LabelsDownloadPage;
  recipeFilter: RecipeFilterWithExclude;
  setLabelColourSetting: (labelColourSetting: LabelColourSetting) => void;
}

function DownloadLabelForm(props: DownloadLabelFormProps) {
  const {
    chosenLabelSize,
    chosenLabelType,
    labelColourSetting,
    onCancel,
    page,
    recipeFilter,
    setLabelColourSetting,
  } = props;

  const graphQL = useGraphQL();
  const handleExport = useHandleLabelExport();
  const intl = useIntl();
  const [includePackaging, setIncludePackaging] = useState(true);
  const [labelColourSettingHoverLabel, setLabelColourSettingHoverLabel] =
    useState<LabelColourSetting | null>(null);
  const isFoodManufacturerOrganization = useFoodManufacturerOrganization();

  const { excludedIds, ...recipeFilterForQuery } = recipeFilter;

  const { status: recipeCountStatus } = useQuery<
    RecipeCountQuery,
    RecipeCountQueryVariables
  >(recipeCountQuery, { recipeFilter: recipeFilterForQuery });
  const chosenLabelTypes = chosenLabelType === null ? [] : [chosenLabelType];

  const colourLabel = intl.formatMessage({
    defaultMessage: "Colour",
    id: "components/labels/DownloadLabelsModal:DownloadLabelsForm/colourSettingRadioButton/colour",
  });

  const monochromeLabel = intl.formatMessage({
    defaultMessage: "Monochrome",
    id: "components/labels/DownloadLabelsModal:DownloadLabelsForm/colourSettingRadioButton/monochrome",
  });

  const renderColourSettingLabel = (colourSetting: LabelColourSetting) => {
    return colourSetting === "colour" ? colourLabel : monochromeLabel;
  };

  async function* fetchRecipePages({
    includePackaging,
    recipeFilter,
  }: {
    includePackaging: boolean;
    recipeFilter: RecipeFilter;
  }): AsyncGenerator<Array<Recipe>> {
    let after: string | null = null;

    while (true) {
      const response: RecipesQuery = await graphQL.fetch<
        RecipesQuery,
        RecipesQueryVariables
      >({
        query: recipesQuery,
        variables: {
          after,
          excludePackaging: !includePackaging,
          first: 1000,
          recipeFilter,
        },
      });

      yield response.recipes.edges.map((edge) => edge.node);

      if (response.recipes.pageInfo.hasNextPage) {
        after = response.recipes.pageInfo.endCursor;
      } else {
        return;
      }
    }
  }

  async function handleSubmit({
    includePackaging,
    labelColourSetting,
    labelType,
    recipeFilter,
    labelSize,
  }: {
    includePackaging: boolean;
    labelColourSetting: LabelColourSetting;
    labelType: CarbonLabelType;
    recipeFilter: RecipeFilterWithExclude;
    labelSize: CarbonLabelSizeOption;
  }) {
    if (chosenLabelType && chosenLabelSize) {
      const recipes: Array<DownloadLabelsModal_Recipe> = [];
      const { excludedIds, ...recipeFilterForQuery } = recipeFilter;
      for await (const recipePage of fetchRecipePages({
        includePackaging,
        recipeFilter: recipeFilterForQuery,
      })) {
        const includedRecipes = recipePage.filter(
          (recipe) => !excludedIds?.includes(recipe.id)
        );
        recipes.push(...includedRecipes);
      }
      return handleExport(
        includePackaging,
        labelType,
        labelColourSetting,
        page,
        recipes,
        labelSize
      );
    }
  }

  return (
    <Form
      onSubmit={() =>
        handleSubmit({
          includePackaging,
          labelColourSetting,
          labelType: chosenLabelType!,
          recipeFilter,
          labelSize: chosenLabelSize!,
        })
      }
      className="h-100"
    >
      <div
        className="d-flex flex-column h-100"
        style={{
          gap: "1rem",
        }}
      >
        <h6 className="semi-bold-font mb-0">
          <FormattedMessage
            id="components/labels/DownloadLabelsModal:labelSettings"
            defaultMessage="Label settings"
          />
        </h6>
        <div className="mb-4">
          <RadioButtons<LabelColourSetting>
            buttonStyle="square"
            hoverValue={labelColourSettingHoverLabel}
            inline={true}
            onChange={setLabelColourSetting}
            optionKey={renderColourSettingLabel}
            options={["colour", "monochrome"]}
            renderOptionLabel={renderColourSettingLabel}
            setHoverLabel={setLabelColourSettingHoverLabel}
            value={labelColourSetting}
          />
        </div>
        <h6 className="semi-bold-font mb-0">
          <FormattedMessage
            id="components/labels/DownloadLabelsModal:impactSettings"
            defaultMessage="Impact settings"
          />
        </h6>
        {!isFoodManufacturerOrganization && (
          <PackagingSwitch
            checked={includePackaging}
            onChange={setIncludePackaging}
            whenAvailableLabelText={true}
          />
        )}
        {chosenLabelTypes.length === 0 ? (
          <div className="text-muted mt-auto">
            <FormattedMessage
              id="components/labels/DownloadLabelsModal:infoMessage/selectLabelType"
              defaultMessage="Select label design"
            />
          </div>
        ) : null}
        <div
          className={classNames(
            "d-flex align-items-center modal-button-container flex-row",
            {
              "mt-auto": chosenLabelTypes.length > 0,
            }
          )}
          style={{ gap: "1rem" }}
        >
          <SecondaryButton onClick={onCancel} className="cancel-button">
            <FormattedMessage
              id="components/labels/DownloadLabelsModal:downloadButton/cancel"
              defaultMessage="Cancel"
            />
          </SecondaryButton>
          <div>
            <StatusDisplay status={recipeCountStatus}>
              {({ recipeCount }) => (
                <Form.SubmitButton
                  className="export-button"
                  disabled={
                    chosenLabelType === null ||
                    chosenLabelSize === undefined ||
                    recipeCount === 0
                  }
                  loadingLabel={
                    <>
                      <FormattedMessage
                        id="components/labels/DownloadLabelsModal:downloadButton/loadingLabel"
                        defaultMessage="Exporting Labels"
                      ></FormattedMessage>
                    </>
                  }
                  submitLabel={
                    <>
                      <Generate height={16} width={16} className="mb-1 mr-2" />
                      {chosenLabelTypes.length === 0 ? (
                        <FormattedMessage
                          id="components/labels/DownloadLabelsModal:downloadButton/label"
                          defaultMessage="Export labels"
                        ></FormattedMessage>
                      ) : (
                        <FormattedMessage
                          id="components/labels/DownloadLabelsModal:downloadButton/numberedLabel"
                          defaultMessage="Export {numLabels, plural, one {# label} other {# labels}}"
                          values={{
                            numLabels:
                              (recipeCount - (excludedIds?.length ?? 0)) *
                              chosenLabelTypes.length,
                          }}
                        ></FormattedMessage>
                      )}
                    </>
                  }
                />
              )}
            </StatusDisplay>
          </div>
        </div>
      </div>
    </Form>
  );
}

function useHandleLabelExport() {
  const intl = useIntl();
  const { trackLabelsDownloaded } = useTracking();

  return async (
    includePackaging: boolean,
    labelType: CarbonLabelType,
    labelColourSetting: LabelColourSetting,
    page: LabelsDownloadPage,
    recipes: Array<DownloadLabelsModal_Recipe>,
    labelSize: CarbonLabelSizeOption
  ) => {
    trackLabelsDownloaded({
      colourSetting: labelColourSetting,
      includePackaging,
      labelSize: labelSizeToDisplayedSize(labelSize),
      labelType: labelTypeToDisplayedType(labelType),
      numLabels: recipes.length,
      page,
    });

    const monochrome = labelColourSetting === "monochrome";

    const zip = new JSZip();

    for (const recipe of recipes) {
      const impactRating = recipe.impact.impactRating!;
      if (impactRating === null) {
        continue;
      }
      const isHotDrink = recipe.isHotDrink;

      let contentsBase64: string;
      if (labelType === "descriptiveImpactAndScale") {
        contentsBase64 = await generateCarbonLabelPngBase64({
          effectType: effectType(isHotDrink),
          impactMagnitude: impactMagnitude(isHotDrink, recipe.impact.effects),
          impactRating,
          monochrome,
          type: labelType,
        });
      } else if (
        labelType === "descriptiveRatingScale" ||
        labelType === "titledRatingScale"
      ) {
        contentsBase64 = await generateCarbonLabelPngBase64({
          impactRating,
          monochrome,
          type: labelType,
        });
      } else if (
        labelType === "descriptiveRating" ||
        labelType === "letterRating" ||
        labelType === "ratingScale"
      ) {
        contentsBase64 = await generateCarbonLabelPngBase64({
          impactRating,
          monochrome,
          size: labelSize!,
          type: labelType,
        });
      } else assertNever(labelType, "Invalid label type");

      zipFileFromBase64Contents({
        contentsBase64,
        includePackaging,
        intl,
        labelName: "Carbon Label",
        recipe,
        zip,
      });
    }

    const zipContent = await zip.generateAsync({ type: "blob" });
    saveAs(zipContent, "labels.zip");
  };
}

function zipFileFromBase64Contents({
  contentsBase64,
  includePackaging,
  index,
  intl,
  labelName,
  recipe,
  zip,
}: {
  contentsBase64: string;
  includePackaging: boolean;
  index?: number;
  intl: IntlShape;
  labelName: string;
  recipe: DownloadLabelsModal_Recipe;
  zip: JSZip;
}) {
  const indexString = index === undefined ? "" : `${index + 1}-`;
  zip.file(
    `recipe-${recipe.id}-${slugifyString(
      recipe.name
    )}-${indexString}${slugifyString(labelName)}${getPackagingSuffix(
      includePackaging,
      intl,
      recipe
    )}.png`,
    contentsBase64,
    { base64: true }
  );
}

function getPackagingSuffix(
  includePackaging: Boolean,
  intl: IntlShape,
  recipe: DownloadLabelsModal_Recipe
) {
  const packagingSuffix = intl.formatMessage({
    id: "components/labels/DownloadLabelsModal:packagingSuffix",
    defaultMessage: "(packaged)",
  });

  if (includePackaging && recipe.packagingComponentsV2.length > 0) {
    return packagingSuffix;
  } else {
    return "";
  }
}

DownloadLabelsModal.fragments = {
  recipe: gql`
    fragment DownloadLabelsModalInput_Recipe on Recipe {
      id
    }
  `,
};

const recipeCountQuery = gql`
  query DownloadLabelsModal_RecipeCountQuery($recipeFilter: RecipeFilter!) {
    recipeCount(filter: $recipeFilter)
  }
`;

const recipesQuery = gql`
  query DownloadLabelsModal_RecipesQuery(
    $after: String
    $excludePackaging: Boolean!
    $first: Int!
    $recipeFilter: RecipeFilter!
  ) {
    recipes(after: $after, first: $first, filter: $recipeFilter) {
      edges {
        node {
          ...DownloadLabelsModal_Recipe
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  fragment DownloadLabelsModal_Recipe on Recipe {
    id
    isHotDrink
    name
    impact(excludePackaging: $excludePackaging, fetchStaleImpacts: false) {
      effects {
        ghgPerKg
        ghgPerRootRecipeServing
      }
      impactRating
    }
    packagingComponentsV2 {
      packagingComponent {
        id
      }
    }
  }
`;
