import { gql } from "graphql-tag";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import {
  AddRecipeInput,
  UpdateRecipeInput,
} from "../../../__generated__/globalTypes";
import useAvailableIngredients from "../../../data-store/useAvailableIngredients";
import useCollections from "../../../data-store/useCollections";
import AvailableIngredient from "../../../domain/AvailableIngredient";
import { useTracking } from "../../../tracking";
import * as statuses from "../../../util/statuses";
import UserVisibleError from "../../../util/UserVisibleError";
import useMutation from "../../graphql/useMutation";
import {
  extractNodesFromPagedQueryResult,
  usePagedQueryFetchAll,
} from "../../graphql/usePagedQuery";
import { useOrganizationId } from "../../organizations/OrganizationProvider";
import StatusDisplay from "../../StatusDisplay";
import * as FloatInput from "../../utils/FloatInput";
import { combineReadResults } from "../../utils/ReadResult";
import * as RecipeCollectionsEditor from "../RecipeEditor/RecipeCollectionsEditor";
import * as RecipeIngredientsEditor from "../RecipeEditor/RecipeIngredientsEditor";
import * as RecipeNameEditor from "../RecipeEditor/RecipeNameEditor";
import Packaging, { PackagingState } from "./Packaging";
import * as PackagingComponentsEditor from "./PackagingComponentsEditor";
import { Value as PhysicalInputValue } from "./PhysicalInputEditor";
import * as PhysicalInputsEditor from "./PhysicalInputsEditor";
import * as PhysicalOutputEditor from "./PhysicalOutputEditor";
import Processing, { ProcessingState } from "./Processing";
import * as ProductDetails from "./ProductDetails";
import {
  AddRecipe,
  AddRecipeVariables,
  ProductEditor_Collection as Collection,
  ProductEditor_PackagingComponent as PackagingComponent,
  ProductEditor_PackagingComponentsQuery as PackagingComponentsQuery,
  ProductEditor_PackagingComponentsQueryVariables as PackagingComponentsQueryVariables,
  ProductEditor_Recipe,
  UpdateProduct,
  UpdateProductVariables,
} from "./ProductEditor.graphql";
import ProductEditorHeader, { StepName } from "./ProductEditorHeader";
import * as ProductWeightEditor from "./ProductWeightEditor";
import * as SiteNameEditor from "./SiteNameEditor";
import "./ProductEditor.css";

export interface ProductEditorState {
  physicalOutput: PhysicalOutputEditor.Value;
  name: RecipeNameEditor.Value;
  collectionIds: RecipeCollectionsEditor.Value;
  ingredients: PhysicalInputsEditor.Value;
  productWeight: ProductWeightEditor.Value;
  siteName: SiteNameEditor.Value;
  amountKwh: FloatInput.Value;
  packagingComponents: PackagingComponentsEditor.Value;
  stepsWithError: Array<StepName>;
}

function initialState(recipe?: ProductEditor_Recipe): ProductEditorState {
  const energyUses = recipe?.unitProcess?.energyUses;
  let amountKwh: number | undefined = undefined;
  if (energyUses && energyUses.length > 0) {
    amountKwh = energyUses[0]?.amount_kwh;
  }

  return {
    physicalOutput: PhysicalOutputEditor.initialValue(recipe),
    name: RecipeNameEditor.initialValue(recipe),
    collectionIds: RecipeCollectionsEditor.initialValue(recipe),
    ingredients: PhysicalInputsEditor.initialValue(recipe),
    productWeight: ProductWeightEditor.initialValue(recipe),
    siteName: SiteNameEditor.initialValue(),
    amountKwh: FloatInput.initialValue(amountKwh ?? null),
    packagingComponents: PackagingComponentsEditor.initialValue(recipe),
    stepsWithError: [],
  };
}

interface ProductEditorProps {
  contentContainerRef?: any;
  parentUrl: string;
  recipe?: ProductEditor_Recipe;
}

export default function ProductEditor(props: ProductEditorProps) {
  const { contentContainerRef, parentUrl, recipe = undefined } = props;

  const history = useHistory();
  const intl = useIntl();
  const [organizationId] = useOrganizationId();
  const { collectionsStatus, refreshCollections } = useCollections();
  const [availableIngredientsStatus] = useAvailableIngredients(organizationId);
  const packagingComponentsStatus = usePackagingComponents();
  const { trackRecipeEditSubmitted } = useTracking();

  const [state, setState] = useState<ProductEditorState>(initialState(recipe));

  const [addRecipe] = useMutation<AddRecipe, AddRecipeVariables>(
    addRecipeMutation
  );

  const [updateRecipe] = useMutation<UpdateProduct, UpdateProductVariables>(
    updateRecipeMutation
  );

  const handleProductDetailsChange = (
    productDetailsState: ProductDetails.ProductDetailsState
  ) => {
    setState({ ...state, ...productDetailsState });
  };

  const handleProcessingChange = (processingState: ProcessingState) => {
    setState({ ...state, ...processingState });
  };

  const handlePackagingChange = (packagingState: PackagingState) => {
    setState({ ...state, ...packagingState });
  };
  const ingredientHasInvalidNameOrRecipe = (ingredient: PhysicalInputValue) =>
    ingredient.invalidName || ingredient.invalidUseRecipe;

  const ingredientHasInvalidProcessLossOrQuantity = (
    ingredient: PhysicalInputValue
  ) =>
    ingredient.unitProcess.processLoss.isInvalid ||
    ingredient.quantity.isInvalid;

  const getStepsWithError = (sectionReadResults: Record<any, any>) => {
    const stepsWithError: Array<StepName> = [];

    const productDetailsKeys: Array<keyof ProductDetails.ProductDetailsState> =
      ["name", "collectionIds", "ingredients", "productWeight"];
    const processingKeys: Array<keyof ProcessingState> = [
      "amountKwh",
      "physicalOutput",
      "ingredients",
      "siteName",
    ];
    const packagingKeys: Array<keyof PackagingState> = ["packagingComponents"];

    const addToStepsWithError = (stepWithError: StepName) => {
      if (!stepsWithError.includes(stepWithError)) {
        stepsWithError.push(stepWithError);
      }
    };

    for (const [key, sectionReadResult] of Object.entries(sectionReadResults)) {
      if (sectionReadResult.hasError) {
        if ((productDetailsKeys as Array<string>).includes(key)) {
          if (
            key === "ingredients" &&
            !sectionReadResults.ingredients.value.some(
              ingredientHasInvalidNameOrRecipe
            )
          ) {
            // do nothing - since ingredient error is not due to an invalid input in the product details section
          } else {
            addToStepsWithError("productDetails");
          }
        }
        if ((processingKeys as Array<string>).includes(key)) {
          if (
            key === "ingredients" &&
            !sectionReadResults.ingredients.value.some(
              ingredientHasInvalidProcessLossOrQuantity
            )
          ) {
            // do nothing - since ingredient error is not due to an invalid input in the processing section
          } else {
            addToStepsWithError("processing");
          }
        }
        if ((packagingKeys as Array<string>).includes(key)) {
          addToStepsWithError("packaging");
        }
      }
    }
    return stepsWithError;
  };

  const trackSubmit = (
    newRecipe: boolean,
    recipeId: number,
    input: AddRecipeInput
  ) => {
    trackRecipeEditSubmitted({
      newRecipe,
      recipeId,
      recipeName: input.name,
      simpleIngredientCount: input.ingredients.filter(
        (ingredient) => ingredient.useRecipeId == null
      ).length,
      subrecipeIngredientCount: input.ingredients.filter(
        (ingredient) => ingredient.useRecipeId != null
      ).length,
    });
  };

  const handleSubmit = async () => {
    const sectionReadResults = {
      collectionIds: RecipeCollectionsEditor.read(state.collectionIds),
      ingredients: PhysicalInputsEditor.read(state.ingredients),
      name: RecipeNameEditor.read(state.name),
      productWeight: ProductWeightEditor.read(state.productWeight),
      // TODO: uncomment when we add support for the process tab (which will include making site information non-empty)
      // siteName: SiteNameEditor.read(state.siteName),
      amountKwh: FloatInput.readAllowEmpty({ value: state.amountKwh }),
      physicalOutput: PhysicalOutputEditor.read(state.physicalOutput),
      packagingComponents: PackagingComponentsEditor.read(
        state.packagingComponents
      ),
    };

    const readResult = combineReadResults(sectionReadResults);
    const stepsWithError = getStepsWithError(sectionReadResults);

    setState({
      ...readResult.value,
      siteName: state.siteName,
      stepsWithError,
    });

    if (readResult.hasError) {
      throw new UserVisibleError(
        intl.formatMessage({
          id: "components/recipes/ProductEditor/ProductEditor:missingInformationMessage",
          defaultMessage:
            "Please make sure you have entered all the required information in the correct format.",
        })
      );
    }

    const input: AddRecipeInput | UpdateRecipeInput = {
      name: readResult.input.name.name,
      numServings: 1,
      collectionIds: readResult.input.collectionIds,
      cookingPenalties: [],
      coProducts: readResult.input.physicalOutput.coProducts,
      hasCompleteCookingPenalties: true,
      hasCompletePostPreparationStoragePenalties: true,
      ingredients: readResult.input.ingredients,
      isCooked: false,
      isHotDrink: false,
      isStored: false,
      ownerOrganizationId: organizationId,
      packagingComponentsV2: readResult.input.packagingComponents,
      productMassQuantity: readResult.input.productWeight.quantity,
      productMassUnit: readResult.input.productWeight.unit,
      productProcessingMassQuantity:
        readResult.input.physicalOutput.finalProductWeightQuantity,
      productProcessingMassUnit:
        readResult.input.physicalOutput.finalProductWeightUnit,
      unitProcess: {
        economicValuePerKg: readResult.input.physicalOutput.finalEconomicValue,
        energyUses: [],
        finalProductLoss:
          readResult.input.physicalOutput.finalProductLoss != null
            ? readResult.input.physicalOutput.finalProductLoss / 100
            : null,
      },
    };

    if (readResult.input.amountKwh !== null) {
      input.unitProcess?.energyUses.push({
        amountKwh: readResult.input.amountKwh,
      });
    }

    if (recipe !== undefined) {
      await updateRecipe({
        variables: {
          input: {
            ...input,
            id: recipe.id,
          },
        },
      });
      trackSubmit(false, recipe.id, input);
    } else {
      const response = await addRecipe({
        variables: {
          input,
        },
      });
      trackSubmit(true, response.addRecipe.recipe.id, input);
    }

    history.push(parentUrl);
  };

  const handleBack = () => {
    history.push(parentUrl);
  };

  return (
    <StatusDisplay.Many<
      [Array<AvailableIngredient>, Array<Collection>, Array<PackagingComponent>]
    >
      statuses={[
        availableIngredientsStatus,
        collectionsStatus,
        packagingComponentsStatus,
      ]}
    >
      {(availableIngredients, collections, packagingComponents) => (
        <div className="ProductEditor">
          <ProductEditorHeader
            editOrCreate={recipe === undefined ? "create" : "edit"}
            onBack={handleBack}
            onSubmit={handleSubmit}
            packaging={
              <Packaging
                packagingComponents={packagingComponents}
                state={state}
                onChange={handlePackagingChange}
              />
            }
            productDetails={
              <ProductDetails.ProductDetails
                contentContainerRef={contentContainerRef}
                availableIngredients={availableIngredients}
                collections={collections}
                refreshCollections={refreshCollections}
                state={state}
                onChange={handleProductDetailsChange}
              />
            }
            processing={(onBack) => (
              <Processing
                contentContainerRef={contentContainerRef}
                showSiteNameSection={false}
                state={state}
                onBack={onBack}
                onChange={handleProcessingChange}
              />
            )}
            stepsWithError={state.stepsWithError}
          />
        </div>
      )}
    </StatusDisplay.Many>
  );
}

function usePackagingComponents(): statuses.Status<Array<PackagingComponent>> {
  const [organizationId] = useOrganizationId();

  const { status } = usePagedQueryFetchAll<
    PackagingComponentsQuery,
    PackagingComponentsQueryVariables,
    PackagingComponent
  >(
    packagingComponentsQuery,
    { organizationId },
    (data) => data.packagingComponents
  );

  return statuses.map(status, extractNodesFromPagedQueryResult);
}

const addRecipeMutation = gql`
  mutation AddRecipe($input: AddRecipeInput!) {
    addRecipe(input: $input) {
      recipe {
        id
      }
    }
  }
`;

const updateRecipeMutation = gql`
  mutation UpdateProduct($input: UpdateRecipeInput!) {
    updateRecipe(input: $input) {
      recipe {
        id
      }
    }
  }
`;

ProductEditor.fragments = {
  recipe: () => gql`
    fragment ProductEditor_Recipe on Recipe {
      id
      unitProcess {
        energyUses {
          amount_kwh
        }
        finalProductLoss
      }
      ...RecipeCollectionsEditor_Recipe
      ...RecipeIngredientsEditor_Recipe
      ...ProductWeightEditor_Recipe
      ...PhysicalInputsEditor_Recipe
      ...PhysicalOutputEditor_Recipe
      ...RecipeNameEditor_Recipe
      ...PackagingComponentsEditor_Recipe
    }

    ${RecipeCollectionsEditor.fragments.recipe}
    ${RecipeIngredientsEditor.fragments.recipe}
    ${RecipeNameEditor.fragments.recipe}
    ${ProductWeightEditor.fragments.recipe()}
    ${PhysicalInputsEditor.fragments.recipe}
    ${PhysicalOutputEditor.fragments.recipe()}
    ${PackagingComponentsEditor.fragments.recipe}
  `,
};

export const collectionFragment = () => gql`
  fragment ProductEditor_Collection on RecipeCollection {
    ...ProductDetails_Collection
  }
  ${ProductDetails.fragments.collection}
`;

const packagingComponentsQuery = gql`
  query ProductEditor_PackagingComponentsQuery(
    $organizationId: UUID!
    $after: String
  ) {
    packagingComponents(
      first: 100
      after: $after
      filter: {
        anyOf: [
          { organizationId: $organizationId }
          { isStandardComponent: true }
        ]
      }
    ) {
      edges {
        node {
          ...ProductEditor_PackagingComponent
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }

  fragment ProductEditor_PackagingComponent on PackagingComponentV2 {
    ...Packaging_PackagingComponent
  }

  ${Packaging.fragments.packagingComponent}
`;
