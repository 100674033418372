import UserVisibleError from "../../util/UserVisibleError";
import { createUnauthenticatedHttpClient, HttpResponse } from "./Client";

export interface SignUpRequestInput {
  email: string;
  emailMarketingConsent: boolean;
  endMileTypeDecisionTreeResponse: string;
  firstName: string;
  userLocale: string | null;
  lastName: string;
  locationId: number;
  organizationName: string;
  parentOrganizationId: string | null;
  password: string;
  signUpOverride: string | null;
  trackingProviderDistinctId: string;
}

export function useSignUp(): (input: SignUpRequestInput) => Promise<void> {
  const httpClient = createUnauthenticatedHttpClient();

  return async (input: SignUpRequestInput) => {
    const response: HttpResponse = await httpClient.request({
      method: "POST",
      path: "api/v1/sign-up/",
      body: input,
    });

    if (response.status !== 200) {
      let message: string | null;
      try {
        message = (await response.json()).message;
      } catch {
        message = null;
      }

      if (message) {
        throw new UserVisibleError(message);
      } else {
        throw new UserVisibleError(
          `Failed to sign up for account (code ${response.status})`
        );
      }
    }
  };
}
