import { ImpactRating } from "../../__generated__/globalTypes";
import { effectTypes } from "../../domain/EffectType";
import { FunctionalUnit } from "../../domain/functionalUnits";
import { ImpactCategory } from "../../domain/impactCategories";
import { qualitativeDataColors } from "../graphs/colors";
import IngredientImpactChart from "./IngredientImpactChart";

const stories = {
  name: "IngredientImpactChart",
  render: () => <Story />,
};

function Story() {
  return (
    <IngredientImpactChart
      functionalUnit={FunctionalUnit.KG}
      perPortionEffectType={effectTypes.ghgPerKg}
      productWeightKgPerPortion={2}
      impactCategory={ImpactCategory.LAND_USE}
      ingredients={[
        {
          name: "Ingredient 1",
          ingredientId: 1,
          dataQualityScore: {
            ghg: 1,
            land_use: 1,
            water_use: 1,
          },
          effects: {
            ghgPerKg: 1,
            ghgPerRootRecipeServing: 1,
            landUsePerKg: 1,
            landUsePerRootRecipeServing: 1,
            waterUsePerKg: 1,
            waterUsePerRootRecipeServing: 1,
          },
          impactRating: ImpactRating.VLOW,
        },
        {
          name: "Ingredient 2",
          ingredientId: 2,
          dataQualityScore: {
            ghg: 1,
            land_use: 2,
            water_use: 3,
          },
          effects: {
            ghgPerKg: 1,
            ghgPerRootRecipeServing: 2,
            landUsePerKg: 1,
            landUsePerRootRecipeServing: 2,
            waterUsePerKg: 1,
            waterUsePerRootRecipeServing: 2,
          },
          impactRating: ImpactRating.LOW,
        },
        {
          name: "Ingredient 3",
          ingredientId: 3,
          dataQualityScore: {
            ghg: 5,
            land_use: 4,
            water_use: 3,
          },
          effects: {
            ghgPerKg: 2,
            ghgPerRootRecipeServing: 3,
            landUsePerKg: 6,
            landUsePerRootRecipeServing: 10,
            waterUsePerKg: 3,
            waterUsePerRootRecipeServing: 6,
          },
          impactRating: ImpactRating.MEDIUM,
        },
        {
          name: "Ingredient 4",
          ingredientId: 4,
          dataQualityScore: {
            ghg: 2,
            land_use: 4,
            water_use: 1.4,
          },
          effects: {
            ghgPerKg: 4,
            ghgPerRootRecipeServing: 8,
            landUsePerKg: null,
            landUsePerRootRecipeServing: null,
            waterUsePerKg: 1.5,
            waterUsePerRootRecipeServing: 1.5,
          },
          impactRating: ImpactRating.HIGH,
        },
        {
          name: "Ingredient 5",
          ingredientId: 5,
          dataQualityScore: {
            ghg: 1,
            land_use: 1,
            water_use: 1,
          },
          effects: {
            ghgPerKg: 4,
            ghgPerRootRecipeServing: 2,
            landUsePerKg: 1,
            landUsePerRootRecipeServing: 2,
            waterUsePerKg: null,
            waterUsePerRootRecipeServing: null,
          },
          impactRating: ImpactRating.VHIGH,
        },
      ]}
      ingredientIdsToColor={
        new Map([1, 2, 3, 4, 5].map((i) => [i, qualitativeDataColors[i]]))
      }
      totalIngredientImpactMagnitude={50}
    />
  );
}

export default stories;
