import gql from "graphql-tag";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { EffectType } from "../../__generated__/globalTypes";
import {
  effectTypes,
  impactCategoryToEffectTypePerKg,
  useImpactCategoryEffectTypePerPortion,
} from "../../domain/EffectType";
import { ImpactCategory } from "../../domain/impactCategories";
import {
  useCarbonLabels,
  useOrganizationSystemBoundaryIsCradleToGrave,
} from "../../services/useOrganizationFeatures";
import assertNever from "../../util/assertNever";
import CarbonLabelsSalesPrompt from "../labels/CarbonLabelsSalesPrompt";
import RecipePageCarbonLabel from "../labels/RecipePageCarbonLabel";
import LargeNumber from "../typography/LargeNumber";
import Number from "../typography/Number";
import { Panel } from "../utils/Panel";
import TooltipOverlay from "../utils/TooltipOverlay";
import HotDrink from "../utils/Vectors/HotDrink";
import { SmartphoneCharges } from "../utils/Vectors/illustrations";
import {
  FoodServiceImpactSummary_EffectTypeImpactRatingInfo,
  FoodServiceImpactSummary_Recipe,
  FoodServiceImpactSummary_RecipeImpact,
} from "./FoodServiceImpactSummary.graphql";
import ImpactCategoryToggle, {
  ImpactCategoryToggleType,
} from "./ImpactCategoryToggle";
import ImpactRatingExplanation from "./ImpactRatingExplanation";
import "./FoodServiceImpactSummary.css";

interface FoodServiceImpactSummaryProps {
  effectTypeForImpactRating: EffectType;
  impact: FoodServiceImpactSummary_RecipeImpact;
  impactRatingInfos: Array<FoodServiceImpactSummary_EffectTypeImpactRatingInfo>;
  recipe: FoodServiceImpactSummary_Recipe;
  showExportButton: boolean;
}

export default function FoodServiceImpactSummary(
  props: FoodServiceImpactSummaryProps
) {
  const {
    effectTypeForImpactRating,
    impact,
    impactRatingInfos,
    recipe,
    showExportButton,
  } = props;

  const carbonLabels = useCarbonLabels();
  const intl = useIntl();
  const showCradleToGraveImpactRating =
    useOrganizationSystemBoundaryIsCradleToGrave();

  const domainEffectTypeForImpactRating =
    effectTypeForImpactRating === EffectType.GHG_PER_SERVING
      ? effectTypes.ghgPerServing
      : effectTypes.ghgPerKg;

  const HotDrinkTooltip = () => (
    <TooltipOverlay
      id="components/recipes/FoodServiceImpactSummary:hotDrinkIconTooltip"
      overlay={
        <FormattedMessage
          id="components/recipes/FoodServiceImpactSummary:hotDrinkIconTooltipMessage"
          defaultMessage="Hot drinks use a unique A - E per serving rating scale to account for dilution"
        />
      }
      placement="top"
    >
      <HotDrink width={32} />
    </TooltipOverlay>
  );

  return (
    <Panel className="FoodServiceImpactSummary_Panel">
      <h3 className="mb-0">
        {intl.formatMessage({
          id: "components/recipes/FoodServiceImpactSummary:summaryTitle",
          defaultMessage: "Impact Summary",
        })}
      </h3>
      <div className="FoodServiceImpactSummary_Body">
        <RecipeSummary impact={impact} />
        {showCradleToGraveImpactRating && (
          <>
            <div className="FoodServiceImpactSummary_GhgRating">
              <div className="FoodServiceImpactSummary_GhgRating_Header">
                {recipe.isHotDrink ? (
                  <div className="mt-n2 d-flex mr-2">
                    <HotDrinkTooltip />
                  </div>
                ) : null}
                <div className="medium-font">
                  {intl.formatMessage({
                    id: "components/recipes/FoodServiceImpactSummary:labelMeaningTitle",
                    defaultMessage: "Carbon Rating",
                  })}
                </div>
              </div>

              <ImpactRatingExplanation
                effectType={domainEffectTypeForImpactRating}
                impact={impact}
                impactRatingInfos={impactRatingInfos}
              />
            </div>
            <div className="FoodServiceImpactSummary_CarbonLabel_Container">
              {carbonLabels ? (
                <div className="FoodServiceImpactSummary_CarbonLabel">
                  <div className="medium-font">
                    {intl.formatMessage({
                      id: "components/recipes/FoodServiceImpactSummary:carbonLabelTitle",
                      defaultMessage: "Carbon Label",
                    })}
                  </div>
                  <RecipePageCarbonLabel
                    impact={impact}
                    recipe={recipe}
                    showExportButton={showExportButton}
                  />
                </div>
              ) : (
                <CarbonLabelsSalesPrompt />
              )}
            </div>
          </>
        )}
      </div>
    </Panel>
  );
}

interface RecipeSummaryProps {
  impact: FoodServiceImpactSummary_RecipeImpact;
}

function RecipeSummary(props: RecipeSummaryProps) {
  const { impact } = props;

  const intl = useIntl();
  const impactCategoryEffectTypePerPortion =
    useImpactCategoryEffectTypePerPortion;

  const [impactCategory, setImpactCategory] = useState<ImpactCategory>(
    ImpactCategory.GHG
  );

  const effectTypePerKg = impactCategoryToEffectTypePerKg(impactCategory);
  const effectTypePerPortion =
    impactCategoryEffectTypePerPortion(impactCategory);
  const impactPerKg = effectTypePerKg.get(impact);
  const impactPerPortion = effectTypePerPortion.get(impact);

  const equivalentSymbol = () => {
    if (impactCategory === ImpactCategory.GHG) {
      return <SmartphoneCharges width={48} />;
    } else if (impactCategory === ImpactCategory.LAND_USE) {
      return null;
    } else if (impactCategory === ImpactCategory.WATER_USE) {
      return null;
    } else {
      assertNever(impactCategory, "Invalid ImpactCategory");
    }
  };

  return (
    <div className="FoodServiceImpactSummary_RecipeSummary">
      <div className="FoodServiceImpactSummary_RecipeSummary_Impacts">
        <ImpactCategoryToggle
          onChange={setImpactCategory}
          selectedImpactCategory={impactCategory}
          type={ImpactCategoryToggleType.SYMBOL}
        />
        <div>
          <LargeNumber>
            {impactPerKg?.toFixed(effectTypePerKg.decimals)}
          </LargeNumber>
          <small>{effectTypePerKg.unit}</small>
        </div>
        <div>
          <LargeNumber>
            {impactPerPortion?.toFixed(effectTypePerPortion.decimals)}
          </LargeNumber>
          <small>{effectTypePerPortion.unit}</small>
        </div>
      </div>

      {impactPerPortion !== null && effectTypePerPortion.equivalent && (
        <div className="FoodServiceImpactSummary_RecipeSummary_Equivalent">
          <small className="medium-font">
            <FormattedMessage
              id="components/recipes/FoodServiceImpactSummary:equivalentTo"
              defaultMessage="equivalent to"
            />
          </small>
          <div className="FoodServiceImpactSummary_RecipeSummary_Equivalent_SymbolAndDataContainer">
            {equivalentSymbol()}
            <div className="FoodServiceImpactSummary_RecipeSummary_Equivalent_DataContainer">
              <Number>
                {(
                  impactPerPortion / effectTypePerPortion.equivalent.value
                ).toFixed(1)}
              </Number>
              <small>
                {effectTypePerPortion.equivalent.title(intl)}{" "}
                <FormattedMessage
                  id="components/recipes/FoodServiceImpactSummary:per"
                  defaultMessage="per"
                />{" "}
                {effectTypePerPortion.per(intl)}
              </small>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

FoodServiceImpactSummary.fragments = {
  impactRatingInfo: gql`
    fragment FoodServiceImpactSummary_EffectTypeImpactRatingInfo on EffectTypeImpactRatingInfo {
      ...ImpactRatingExplanation_EffectTypeImpactRatingInfo
    }
    ${ImpactRatingExplanation.fragments.impactRatingInfo}
  `,

  impact: gql`
    fragment FoodServiceImpactSummary_RecipeImpact on RecipeImpact {
      effects {
        ghgPerKg
        ghgPerRootRecipeServing
        landUsePerKg
        landUsePerRootRecipeServing
        waterUsePerKg
        waterUsePerRootRecipeServing
      }
      errors {
        kind
      }
      impactRating
      ...ImpactRatingExplanation_RecipeImpact
      ...RecipePageCarbonLabel_RecipeImpact
    }
    ${ImpactRatingExplanation.fragments.recipeImpact}
    ${RecipePageCarbonLabel.fragments.recipeImpact}
  `,

  recipe: gql`
    fragment FoodServiceImpactSummary_Recipe on Recipe {
      isHotDrink
      ...RecipePageCarbonLabel_Recipe
    }
    ${RecipePageCarbonLabel.fragments.recipe}
  `,
};
