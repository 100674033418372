import { gql } from "graphql-tag";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import useCollections from "../../data-store/useCollections";
import { useTracking } from "../../tracking";
import StatusDisplay from "../StatusDisplay";
import AddTagModal from "../tags/AddTagModal";
import TagMultiSelect, {
  TagMultiSelectOptionType,
} from "../tags/TagMultiSelect";
import HelpModalTooltip from "../utils/HelpModalTooltip";
import useId from "../utils/useId";
import RecipeEditorLabel from "./RecipeEditor/RecipeEditorLabel";

interface RecipeTagsEditorProps {
  onChange: (ids: Array<number>) => void;
  selectedIds: ReadonlyArray<number>;
}

export function RecipeTagsEditor(props: RecipeTagsEditorProps) {
  const { onChange, selectedIds } = props;

  const id = useId();
  const { trackTagCreated } = useTracking();
  const { collectionsStatus, addCollection } = useCollections();

  const [showNewTagModal, setShowNewTagModal] = useState<boolean>(false);

  const handleNewTagCreated = (newTag: { id: number; name: string }) => {
    onChange([...selectedIds, newTag.id]);
    setShowNewTagModal(false);
    trackTagCreated({
      tagName: newTag.name,
      tagId: newTag.id,
      workflow: "Edit Product",
    });
  };

  return (
    <div className="form-group">
      <StatusDisplay status={collectionsStatus}>
        {(allCollections) => (
          <>
            <RecipeEditorLabel htmlFor={id}>
              <FormattedMessage
                id="components/recipes/RecipeTagsEditor:label"
                defaultMessage="Tags"
              />
            </RecipeEditorLabel>
            <HelpModalTooltip
              title={
                <FormattedMessage
                  id="components/recipes/RecipeEditor/RecipeTagsEditor:tagsTooltipModal/title"
                  defaultMessage="Tags"
                />
              }
            >
              <FormattedMessage
                id="components/recipes/RecipeEditor/RecipeTagsEditor:tagsTooltipModal/text"
                defaultMessage="A tag is an optional way of categorising your products. Products can have multiple tags."
              />
            </HelpModalTooltip>
            <TagMultiSelect
              menuPlacement="top"
              options={allCollections.map((collection) => {
                return {
                  type: TagMultiSelectOptionType.SIMPLE,
                  id: collection.id,
                  name: collection.name,
                };
              })}
              onChange={(ids) => onChange(ids.map((id) => id))}
              selectedIds={selectedIds}
              onNewTagClicked={() => setShowNewTagModal(true)}
            />
            <AddTagModal
              addTag={addCollection}
              show={showNewTagModal}
              onCancel={() => setShowNewTagModal(false)}
              onComplete={handleNewTagCreated}
            />
          </>
        )}
      </StatusDisplay>
    </div>
  );
}

export const fragments = {
  collection: gql`
    fragment RecipeTagsEditor_Collection on RecipeCollection {
      id
      name
    }
  `,
};
