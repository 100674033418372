import ImpactSummary from "./ImpactSummary";

const stories = {
  name: "ImpactSummary",
  render: () => <Story />,
};

function Story() {
  return (
    <div className="m-3">
      <ImpactSummary
        impact={{
          dataQualityScore: {
            ghg: 2.5,
            land_use: 3.5,
            water_use: 4.5,
          },
          effects: {
            ghgPerKg: 1,
            ghgPerRootRecipeServing: 1,
            landUsePerKg: 0.5,
            landUsePerRootRecipeServing: 0.5,
            waterUsePerKg: 2.1,
            waterUsePerRootRecipeServing: 2.1,
          },
          unavailableWaterUse: false,
          unavailableLandUse: true,
        }}
      />
    </div>
  );
}

export default stories;
