import { useIntl } from "react-intl";

import {
  highD,
  lowB,
  mediumC,
  noRating,
  veryHighE,
  veryLowA,
} from "../components/graphs/colors";
import * as comparators from "../util/comparators";

export type ImpactRating = "VLOW" | "LOW" | "MEDIUM" | "HIGH" | "VHIGH";

export const allImpactRatings: ReadonlyArray<ImpactRating> = [
  "VLOW",
  "LOW",
  "MEDIUM",
  "HIGH",
  "VHIGH",
];

export const comparator: comparators.Comparator<ImpactRating> = comparators.map(
  (rating) => allImpactRatings.indexOf(rating),
  comparators.number
);

export function useImpactRatingToLongName(impactRating: ImpactRating): string {
  const intl = useIntl();

  switch (impactRating) {
    case "VLOW":
      return intl.formatMessage({
        defaultMessage: "Very Low",
        id: "domain/impactRatings:useImpactRatingToLongName/veryLow",
      });
    case "LOW":
      return intl.formatMessage({
        defaultMessage: "Low",
        id: "domain/impactRatings:useImpactRatingToLongName/low",
      });
    case "MEDIUM":
      return intl.formatMessage({
        defaultMessage: "Medium",
        id: "domain/impactRatings:useImpactRatingToLongName/medium",
      });
    case "HIGH":
      return intl.formatMessage({
        defaultMessage: "High",
        id: "domain/impactRatings:useImpactRatingToLongName/high",
      });
    case "VHIGH":
      return intl.formatMessage({
        defaultMessage: "Very High",
        id: "domain/impactRatings:useImpactRatingToLongName/veryHigh",
      });
  }
}

export function impactRatingToLetter(impactRating: ImpactRating): string {
  switch (impactRating) {
    case "VLOW":
      return "A";
    case "LOW":
      return "B";
    case "MEDIUM":
      return "C";
    case "HIGH":
      return "D";
    case "VHIGH":
      return "E";
  }
}

export function impactRatingToColor(impactRating: ImpactRating): string {
  switch (impactRating) {
    case "VLOW":
      return "var(--label-A)";
    case "LOW":
      return "var(--label-B)";
    case "MEDIUM":
      return "var(--label-C)";
    case "HIGH":
      return "var(--label-D)";
    case "VHIGH":
      return "var(--label-E)";
  }
}

export function impactRatingToColorRgb(
  impactRating: ImpactRating | null
): string {
  switch (impactRating) {
    case "VLOW":
      return veryLowA;
    case "LOW":
      return lowB;
    case "MEDIUM":
      return mediumC;
    case "HIGH":
      return highD;
    case "VHIGH":
      return veryHighE;
    case null:
      return noRating;
  }
}
