import classNames from "classnames";
import { gql } from "graphql-tag";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { useImpactCategoryEffectTypePerPortion } from "../../domain/EffectType";
import { ImpactCategory } from "../../domain/impactCategories";
import {
  useCarbonLabels,
  useOrganizationSystemBoundaryIsCradleToGrave,
} from "../../services/useOrganizationFeatures";
import assertNever from "../../util/assertNever";
import { interfaceGrey, whiteCol } from "../graphs/colors";
import CarbonLabel from "../labels/carbon-label/CarbonLabel";
import { RecipesListPageQueryParams } from "../pages";
import Checkbox from "../utils/Checkbox";
import { Panel } from "../utils/Panel";
import Spinner from "../utils/Spinner";
import { PagedTable, TableColumn } from "../utils/Table";
import {
  AttentionNeededWithoutTextbox,
  AttentionTriangle,
  Delete,
  ImpactPendingWithoutTextbox,
  Sharing,
} from "../utils/Vectors";
import * as RecipesListPageLocationState from "./RecipesListPageLocationState";
import { RecipesTable_Recipe as Recipe } from "./RecipesTable.graphql";
import * as RecipeStatus from "./RecipeStatus";
import { useRecipeImpactIsUnavailable } from "./useRecipeImpactIsUnavailable";
import useRecipeLabel from "./useRecipeLabel";

import "./RecipesTable.css";

interface RecipesTableProps {
  impactCategory: ImpactCategory;
  deleteRecipe: (recipe: Recipe) => void;
  onRecipesLoadMore: null | (() => Promise<void>);
  onSelectChange: (id: number, selected: boolean) => void;
  queryParams: RecipesListPageQueryParams;
  recipes: Array<Recipe>;
  recipeUrl: (recipe: Recipe) => string;
  selectedRecipeIds: Array<number>;
  showCollectionsColumn: boolean;
  showSharedIcon: boolean;
  waitingForFreshImpacts: boolean;
}

export default function RecipesTable(props: RecipesTableProps) {
  const {
    deleteRecipe,
    impactCategory,
    onRecipesLoadMore,
    onSelectChange,
    queryParams,
    recipes,
    recipeUrl,
    selectedRecipeIds,
    showCollectionsColumn,
    showSharedIcon,
    waitingForFreshImpacts,
  } = props;

  const hasCarbonLabelsFeature = useCarbonLabels();
  const history = useHistory();
  const intl = useIntl();
  const recipeLabel = useRecipeLabel();
  const recipeStatus = RecipeStatus.useRecipeStatus;
  const recipeImpactIsUnavailable = useRecipeImpactIsUnavailable;
  const effectType = useImpactCategoryEffectTypePerPortion(impactCategory);
  const organizationSystemBoundaryIsCradleToGrave =
    useOrganizationSystemBoundaryIsCradleToGrave();

  const [hoverRecipe, setHoverRecipe] = useState<Recipe | null>(null);

  const handleClickRow = (recipe: Recipe) => {
    const pathname =
      RecipesListPageLocationState.queryParamsToLocationDescriptor(
        recipeUrl(recipe),
        queryParams
      ).pathname;
    history.push(pathname!);
  };

  const renderCollectionNames = (recipe: Recipe) => {
    let collectionNames = recipe.collections.map(
      (collection) => collection.name
    );
    return collectionNames.length > 0 ? collectionNames.join(", ") : "-";
  };

  const renderCarbonRatingIcon = (recipe: Recipe) => {
    const status = recipeStatus(recipe.impact, impactCategory);

    if (status.type === "internalError") {
      return <RecipeStatus.InternalErrorIcon />;
    } else if (status.type === "requiresClientAttention") {
      return <AttentionNeededWithoutTextbox width="46px" height="32px" />;
    } else if (status.type === "pending") {
      return <ImpactPendingWithoutTextbox width="46px" height="32px" />;
    } else if (status.type === "noImpactRating") {
      return null;
    } else if (status.type === "hasImpactRating") {
      return (
        <CarbonLabel
          type="letterRating"
          impactRating={status.impactRating}
          size="small"
        />
      );
    } else if (status.type === "unavailable") {
      return null;
    } else {
      return assertNever(status, "unhandled status");
    }
  };

  const renderImpact = (recipe: Recipe) => {
    const impactMagnitude = effectType.get({
      effects: recipe.impact.effects,
    });
    const dash = (
      <FormattedMessage
        id="components/recipes/RecipesTable:dash"
        defaultMessage="-"
      />
    );
    if (
      recipeImpactIsUnavailable(recipe.impact, impactCategory) ||
      impactMagnitude === null
    ) {
      return dash;
    } else {
      return impactMagnitude.toFixed(effectType.decimals);
    }
  };

  const columns: Array<TableColumn<Recipe>> = [
    {
      className: "pl-2",
      key: "recipeName",
      label: recipeLabel.pluralUppercase,
      renderCell: (recipe, { className }) => {
        return (
          <div
            className={classNames(
              "d-flex align-items-center recipe-name-cell flex-row",
              className ?? "",
              { "recipe-name-cell__highlighted": hoverRecipe?.id === recipe.id }
            )}
          >
            <Checkbox
              checked={selectedRecipeIds.includes(recipe.id)}
              onChange={() =>
                onSelectChange(
                  recipe.id,
                  !selectedRecipeIds.includes(recipe.id)
                )
              }
              propagateOnClick={false}
            />
            {showSharedIcon ? (
              <div>
                <Sharing
                  width={22}
                  fill={
                    selectedRecipeIds.includes(recipe.id)
                      ? whiteCol
                      : interfaceGrey
                  }
                />
              </div>
            ) : null}
            {recipe.name}
            {recipeStatus(recipe.impact, impactCategory).type ===
            "requiresClientAttention" ? (
              <AttentionTriangle className="attention-triangle" width="1.4em" />
            ) : null}
          </div>
        );
      },
      verticalAlign: "middle",
    },
    ...(showCollectionsColumn
      ? [
          {
            className: "collections-column",
            key: "collections",
            label: intl.formatMessage({
              id: "components/recipes/RecipesTable:columns/collections/label",
              defaultMessage: "Collections",
            }),
            renderCell: renderCollectionNames,
            verticalAlign: "middle",
          } as TableColumn<Recipe>,
        ]
      : []),
    {
      align: "center",
      className: "servings-column px-2",
      key: "servings",
      label: intl.formatMessage({
        id: "components/recipes/RecipesTable:columns/servings/label",
        defaultMessage: "Servings",
      }),
      renderCell: (recipe) => recipe.serves ?? null,
      verticalAlign: "middle",
    },
    {
      align: "right",
      className: "carbon-footprint-column px-2",
      key: "carbonFootprint",
      label: effectType.title(intl),
      units: effectType.unit,
      renderCell: (recipe) => (
        <div className="RecipesTable_ImpactCell">
          {recipe.impact.isStale && waitingForFreshImpacts && (
            <div className="RecipesTable_SpinnerContainer">
              <Spinner className="RecipesTable_Spinner" />
            </div>
          )}
          <div
            className={classNames("RecipesTable_ImpactContainer", {
              RecipesTable_Stale: recipe.impact.isStale,
            })}
          >
            {renderImpact(recipe)}
          </div>
        </div>
      ),
      verticalAlign: "middle",
    },
    ...(organizationSystemBoundaryIsCradleToGrave &&
    impactCategory === ImpactCategory.GHG &&
    hasCarbonLabelsFeature
      ? [
          {
            align: "center",
            className: "impact-rating-column px-2",
            key: "impactRating",
            label: intl.formatMessage({
              id: "components/recipes/RecipesTable:columns/impactRating/label",
              defaultMessage: "Rating",
            }),
            renderCell: (recipe) => (
              <div
                className={classNames({
                  RecipesTable_Stale: recipe.impact.isStale,
                })}
              >
                {renderCarbonRatingIcon(recipe)}
              </div>
            ),
            verticalAlign: "middle",
          } as TableColumn<Recipe>,
        ]
      : []),
    ...(recipes.some((recipe) => recipe.viewerHasPermissionUpdate)
      ? [
          {
            className: "delete-recipe-column px-2",
            key: "delete",
            label: "",
            renderCell: (recipe: Recipe) =>
              recipe.viewerHasPermissionUpdate ? (
                <span onClick={(e) => e.stopPropagation()}>
                  <Delete
                    title="Delete Product"
                    handleClick={() => deleteRecipe(recipe)}
                    width="1em"
                  />
                </span>
              ) : null,
            verticalAlign: "middle",
          } as TableColumn<Recipe>,
        ]
      : []),
  ];

  return (
    <div className="recipes-table">
      <Panel className="RecipesTable_Panel">
        <PagedTable<Recipe>
          activeRows={selectedRows(recipes, selectedRecipeIds)}
          columns={columns}
          defaultSort="impact"
          fullWidth
          onLoadMore={onRecipesLoadMore}
          onClickRow={handleClickRow}
          rowClassName="recipe-table-row"
          rowKey={recipeKey}
          rows={recipes}
          setHoverRow={setHoverRecipe}
        />
      </Panel>
    </div>
  );
}

function recipeKey(recipe: Recipe) {
  return recipe.id;
}

function selectedRows(
  recipes: Array<Recipe>,
  selectedRecipeIds: Array<number>
): Array<Recipe> {
  return recipes.filter((recipe) => selectedRecipeIds.includes(recipe.id));
}

RecipesTable.fragments = {
  recipes: gql`
    fragment RecipesTable_Recipe on Recipe {
      collections {
        name
      }
      id
      impact(excludePackaging: false, fetchStaleImpacts: $fetchStaleImpacts) {
        effects {
          ghgPerKg
          ghgPerRootRecipeServing
          landUsePerKg
          landUsePerRootRecipeServing
          waterUsePerKg
          waterUsePerRootRecipeServing
        }
        impactRating
        isStale
        weightKgPerServing
        unavailableLandUse
        unavailableWaterUse

        ...RecipeStatus_RecipeImpact
      }
      name
      serves
      viewerHasPermissionUpdate
    }

    ${RecipeStatus.fragments.recipeImpact}
  `,
};
