export const getNameForLocale = (
  foodWithSynonyms: {
    name: string;
    synonyms: {
      name: string;
      locale: string;
      isDefaultForLocale: boolean;
    }[];
  },
  localeForFoodClasses: string
) => {
  const localeNameAttempt = foodWithSynonyms.synonyms.find(
    (synonym) =>
      synonym.isDefaultForLocale && synonym.locale === localeForFoodClasses
  )?.name;
  return localeNameAttempt || foodWithSynonyms.name;
};
