import { useIntl } from "react-intl";
import { useMemoOne } from "use-memo-one";

import { ImpactCategory } from "../../domain/impactCategories";
import { useTags } from "../../services/useOrganizationFeatures";
import { toMultiMap } from "../../util/maps";
import { collectionColourLists } from "../graphs/colors";
import { createRecipeGroup, RecipeGroupRecipe } from "../graphs/RecipeGroup";
import { getImpactPerKg } from "./DashboardPage";
import GraphCard, { GraphType } from "./GraphCard";
import { Recipe, RecipeCollection } from "./useDashboardPageData";

interface RecipeImpactByCollectionChartCardProps {
  collections: Array<RecipeCollection>;
  impactCategory: ImpactCategory;
  meanImpactPerKg: string;
  recipes: Array<Recipe>;
}

export default function RecipeImpactByCollectionChartCard(
  props: RecipeImpactByCollectionChartCardProps
) {
  const { collections, impactCategory, meanImpactPerKg, recipes } = props;

  const intl = useIntl();
  const hasTagsFeature = useTags();

  const recipeGroups = useMemoOne(() => {
    const recipesByCollectionId = toMultiMap(
      recipes.flatMap((recipe) => {
        const impactPerKg = getImpactPerKg(recipe, impactCategory);
        if (impactPerKg === null) {
          return [];
        } else {
          return recipe.collections.map(
            (collection): [number, RecipeGroupRecipe] => [
              collection.id,
              { name: recipe.name, impactPerKg },
            ]
          );
        }
      })
    );

    return collections.map((collection) => {
      const recipes = recipesByCollectionId.get(collection.id) ?? [];
      return createRecipeGroup({
        id: collection.id,
        name: collection.name,
        recipes,
      });
    });
  }, [collections, impactCategory, recipes]);

  const collectionTitle = intl.formatMessage({
    id: "components/dashboard/RecipeImpactByCollectionChartCard:collection",
    defaultMessage: "Collection",
  });

  const tagTitle = intl.formatMessage({
    id: "components/dashboard/RecipeImpactByCollectionChartCard:tag",
    defaultMessage: "Tag",
  });

  return (
    <GraphCard
      colorLists={collectionColourLists}
      defaultGraphType={GraphType.DOUGHNUT}
      impactCategory={impactCategory}
      mean={meanImpactPerKg}
      recipeCount={recipes.length}
      recipeGroups={recipeGroups}
      title={hasTagsFeature ? tagTitle : collectionTitle}
    />
  );
}
