import classNames from "classnames";

import { ImpactRating } from "../../domain/impactRatings";

import "./ImpactRatingDisplay.css";

export function impactRatingToCssClass(impactRating: ImpactRating): string {
  switch (impactRating) {
    case "VLOW":
      return "impact-rating-display__very-low";
    case "LOW":
      return "impact-rating-display__low";
    case "MEDIUM":
      return "impact-rating-display__medium";
    case "HIGH":
      return "impact-rating-display__high";
    case "VHIGH":
      return "impact-rating-display__very-high";
  }
}

// We pass the impact rating text e.g. "Medium", as a prop,
// rather than converting the value into text in ImpactRatingDisplay,
// because this component is used by a tooltip that is not able to access the IntlProvider.
interface ImpactRatingDisplayProps {
  text: string;
  value: ImpactRating;
}

export default function ImpactRatingDisplay(props: ImpactRatingDisplayProps) {
  const { text, value } = props;

  return (
    <div
      className={classNames(
        "impact-rating-display",
        impactRatingToCssClass(value)
      )}
    >
      {text}
    </div>
  );
}
