import { IntlProvider as BaseIntlProvider } from "react-intl";
import { useLocation } from "react-router-dom";
import { useCallbackOne } from "use-memo-one";

import StatusDisplay from "./components/StatusDisplay";
import usePromise from "./components/utils/usePromise";
import { useUserInfoOrNull } from "./data-store/useUserInfo";

interface IntlProviderProps {
  children: React.ReactNode;
}

export function IntlProvider(props: IntlProviderProps) {
  const { children } = props;
  const locale = useLocale();

  return (
    <IntlProviderWithExplicitLocale locale={locale}>
      {children}
    </IntlProviderWithExplicitLocale>
  );
}

interface IntlProviderWithExplicitLocaleProps {
  children: React.ReactNode;
  locale: string;
}

export function IntlProviderWithExplicitLocale(
  props: IntlProviderWithExplicitLocaleProps
) {
  const { children, locale } = props;

  const loadMessages = useCallbackOne(
    () => loadMessagesForLocale(locale),
    [locale]
  );

  const [messagesStatus] = usePromise(loadMessages);

  return (
    <StatusDisplay status={messagesStatus}>
      {(messages) => (
        <BaseIntlProvider
          messages={messages}
          locale={locale}
          defaultLocale={DEFAULT_LOCALE}
        >
          {children}
        </BaseIntlProvider>
      )}
    </StatusDisplay>
  );
}

function useLocale(): string {
  const userInfo = useUserInfoOrNull();
  const { search } = useLocation();

  const userLocale = userInfo?.locale ?? null;

  const searchParams = new URLSearchParams(search);
  const urlParameterLocale = searchParams.get("platformLanguage");
  if (
    urlParameterLocale !== null &&
    localeToLangImport.has(urlParameterLocale)
  ) {
    return urlParameterLocale;
  }

  if (userLocale !== null) {
    return userLocale;
  }

  return DEFAULT_LOCALE;
}

const DEFAULT_LOCALE = "en-GB";

// returns functions rather then just the import string
// because doing a dynamic import like `import(myLangCompiledFilePath)` caused an error:
// "Critical dependency: the request of a dependency is an expression"
// Which I did not understand
const localeToLangImport = new Map<string, () => Promise<any>>([
  ["de-DE", () => import("./lang-compiled/de-DE.json")],
  ["en-GB", () => import("./lang-compiled/en-GB.json")],
  ["es-419", () => import("./lang-compiled/es-419.json")],
  ["es-ES", () => import("./lang-compiled/es-ES.json")],
  ["fr-FR", () => import("./lang-compiled/fr-FR.json")],
  ["nl-NL", () => import("./lang-compiled/nl-NL.json")],
  ["TEST", () => import("./lang-compiled/TEST.json")],
  ["zh-CN", () => import("./lang-compiled/zh-CN.json")],
]);

function loadMessagesForLocale(locale: string) {
  const importFunction = localeToLangImport.get(locale);
  if (importFunction !== undefined) {
    return importFunction();
  } else {
    return Promise.resolve({});
  }
}
