import classNames from "classnames";
import gql from "graphql-tag";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  EffectType,
  ImpactCalculatorErrorKind,
} from "../../__generated__/globalTypes";
import { useDataStore } from "../../data-store";
import { effectTypes } from "../../domain/EffectType";
import { gramsUnit, kilogramsUnit } from "../../domain/units";
import {
  useFoodManufacturerOrganization,
  useNewLifeCycleImpactChart,
} from "../../services/useOrganizationFeatures";
import UserVisibleError from "../../util/UserVisibleError";
import RecipePageCarbonLabel from "../labels/RecipePageCarbonLabel";
import { PrimaryButton } from "../utils/Button";
import Card from "../utils/Card";
import { Panel } from "../utils/Panel";
import QuestionMarkTooltipOverlay from "../utils/QuestionMarkTooltipOverlay";
import Spinner from "../utils/Spinner";
import SplitButton from "../utils/SplitButton";
import { BrokenLink, Error, PendingImpact } from "../utils/Vectors";
import CardTitle from "./CardTitle";
import ContactUsButton from "./ContactUsButton";
import FoodServiceImpactSummary from "./FoodServiceImpactSummary";
import ImpactComparison from "./ImpactComparison";
import ImpactRatingExplanation from "./ImpactRatingExplanation";
import ImpactSummary from "./ImpactSummary";
import IngredientImpactBreakdown from "./IngredientImpactBreakdown";
import IngredientImpactChart from "./IngredientImpactChart";
import LifeCycleChartCard from "./LifeCycleChartCard";
import LifeCycleImpacts from "./LifeCycleImpacts";
import PackagingComponentImpactSection from "./PackagingComponentImpactSection";
import {
  RecipeBreakdown_EffectTypeImpactRatingInfo as EffectTypeImpactRatingInfo,
  RecipeBreakdown_Recipe as Recipe,
  RecipeBreakdown_RecipeImpact,
} from "./RecipeBreakdown.graphql";
import RecipePackagingComponentTable from "./RecipePackagingComponentTable";
import { recipeImpactGraphqlQuery } from "./RecipePageContent";
import {
  RecipePageContent_RecipeQuery,
  RecipePageContent_RecipeQueryVariables,
} from "./RecipePageContent.graphql";
import { Buttons } from "./RecipePageControls";
import "./RecipeBreakdown.css";
import useRecipeLabel from "./useRecipeLabel";
import "./RecipeIngredientLinking.css";

interface RecipeBreakdownProps {
  effectTypeForImpactRating: EffectType;
  impact: RecipeBreakdown_RecipeImpact;
  impactRatingInfos: Array<EffectTypeImpactRatingInfo>;
  includePackaging: boolean;
  onEdit: () => void;
  recipe: Recipe;
  setRecalculatingImpacts: (recalculatingImpacts: boolean) => void;
  showExportButton: boolean;
}

export default function RecipeBreakdown(props: RecipeBreakdownProps) {
  const {
    effectTypeForImpactRating,
    impact: initialImpact,
    impactRatingInfos,
    includePackaging,
    onEdit,
    recipe,
    setRecalculatingImpacts,
    showExportButton,
  } = props;

  const dataStore = useDataStore();
  const intl = useIntl();
  const isFoodManufacturerOrganization = useFoodManufacturerOrganization();
  const recipeLabel = useRecipeLabel();
  const foodManufacturerOrganization = useFoodManufacturerOrganization();
  const newLifeCycleImpactChart = useNewLifeCycleImpactChart();

  const [impact, setImpact] =
    useState<RecipeBreakdown_RecipeImpact>(initialImpact);

  const ghgPerKg = effectTypes.ghgPerKg.get(impact);
  const ghgPerServing = effectTypes.ghgPerServing.get(impact);
  const hasEffects = ghgPerKg !== null;
  const packagingComponents = recipe?.packagingComponentsV2.map(
    (recipePackagingComponent) => recipePackagingComponent.packagingComponent
  );

  useEffect(() => {
    async function fetchUpdatedRecipeImpact(): Promise<RecipeBreakdown_RecipeImpact> {
      const data = await dataStore.fetchGraphQL<
        RecipePageContent_RecipeQuery,
        RecipePageContent_RecipeQueryVariables
      >({
        query: recipeImpactGraphqlQuery(),
        variables: {
          recipeId: recipe.id,
          fetchStaleImpacts: false,
          effectTypes: [effectTypeForImpactRating],
        },
      });
      if (data.recipe === null) {
        throw new UserVisibleError("Could not fetch updated recipe impact.");
      }
      return includePackaging
        ? data.recipe.impactWithPackaging
        : data.recipe.impactWithoutPackaging;
    }

    async function updateRecipeImpactIfStale() {
      if (impact.isStale) {
        const updatedImpact = await fetchUpdatedRecipeImpact();
        setImpact(updatedImpact);
      }
    }
    updateRecipeImpactIfStale();
  }, [
    impact,
    dataStore,
    effectTypeForImpactRating,
    includePackaging,
    recipe.id,
    setRecalculatingImpacts,
  ]);

  const RecipeNotPackagedSection = () => {
    const editButtonProperties = Buttons.useEditButtonProperties({
      onEdit,
      recipe,
    });
    return (
      <>
        <hr className="mb-4 mt-0" />
        <div className="pb-2">
          <FormattedMessage
            id="components/recipes/RecipeBreakdown:RecipeNotPackagedSection/label"
            defaultMessage="This {recipeLabel} is not packaged."
            values={{ recipeLabel: recipeLabel.singularLowercase }}
          />
        </div>
        {editButtonProperties && (
          <>
            <div className="text-muted">
              <FormattedMessage
                id="components/recipes/RecipeBreakdown:RecipeNotPackagedSection/editRecipeMessage"
                defaultMessage="Edit the {recipeLabel} to add packaging components."
                values={{ recipeLabel: recipeLabel.singularLowercase }}
              />
            </div>
            <div className="py-4">
              <SplitButton items={[editButtonProperties]} variant="secondary" />
            </div>
          </>
        )}
      </>
    );
  };

  const packagingComponentsGhgPerServingValue = packagingComponents.reduce(
    (acc: number, curr) =>
      curr.ghgMagnitude !== null ? acc + curr.ghgMagnitude : 0,
    0
  );
  const packagingImpactPercentageOfTotalRecipe =
    ghgPerServing !== null && ghgPerServing > 0
      ? (100 * packagingComponentsGhgPerServingValue) / ghgPerServing
      : null;

  const missingSummaryPanel = (
    <Panel>
      <div className="text-center">
        <div className="w-50 m-auto">
          <MissingSummaryExplanation
            errors={impact.errors}
            onEdit={onEdit}
            recipe={recipe}
          />
        </div>
      </div>
    </Panel>
  );

  const summary = () => {
    // impacts.effects should always be null if impact.errors.length > 0
    // enforced by graphql resolvers
    // but because they are so confusing to understand at time of writing, check both
    if (impact.effects === null || impact.errors.length > 0) {
      return missingSummaryPanel;
    } else {
      if (foodManufacturerOrganization) {
        // if individual impacts are null, the food manufacture impact summary handles that
        return <ImpactSummary impact={impact} />;
      } else {
        // the food service impact summary doesn't know how to handle nulls
        // so fall back to displaying a generic error
        if (ghgPerKg !== null && ghgPerServing !== null) {
          return (
            <FoodServiceImpactSummary
              effectTypeForImpactRating={effectTypeForImpactRating}
              impact={impact}
              impactRatingInfos={impactRatingInfos}
              recipe={recipe}
              showExportButton={showExportButton}
            />
          );
        } else {
          return missingSummaryPanel;
        }
      }
    }
  };

  const productWeightKgPerPortion = (): number | null => {
    if (foodManufacturerOrganization) {
      if (recipe.productMassUnit === gramsUnit.value) {
        return recipe.productMassQuantity
          ? recipe.productMassQuantity / 1000
          : null;
      } else if (recipe.productMassUnit === kilogramsUnit.value) {
        return recipe.productMassQuantity;
      } else {
        throw new UserVisibleError("Unsupported product mass unit.");
      }
    } else {
      return impact.weightKgPerServing;
    }
  };

  const nullProductWeightErrorMessage = () => {
    if (foodManufacturerOrganization) {
      return intl.formatMessage({
        defaultMessage:
          "Could not find the product weight. Please enter a product weight in the product editor.",
        id: "components/recipes/RecipeBreakdown:nullProductWeightErrorMessage/foodManufacturer",
      });
    } else {
      return intl.formatMessage({
        defaultMessage: "Could not find the product weight.",
        id: "components/recipes/RecipeBreakdown:nullProductWeightErrorMessage/foodService",
      });
    }
  };

  return (
    <div className="RecipeBreakdown">
      <div
        className={classNames("RecipeBreakdown_Container", {
          RecipeBreakdown_Stale: impact.isStale,
        })}
      >
        {summary()}
        <IngredientImpactBreakdown
          impact={impact}
          nullProductWeightErrorMessage={nullProductWeightErrorMessage()}
          productWeightKgPerPortion={productWeightKgPerPortion()}
          recipe={recipe}
        />
        {!isFoodManufacturerOrganization && (
          <Panel
            className={classNames({
              "text-inactive":
                !includePackaging && packagingComponents.length > 0,
            })}
          >
            <div className="row">
              <div className="col-8">
                <CardTitle
                  shortBottomMargin
                  title={intl.formatMessage({
                    id: "components/recipes/RecipeBreakdown:packagingComponentBreakdownTitle",
                    defaultMessage: "Packaging Component Breakdown",
                  })}
                />
                <div style={{ maxHeight: 250, overflow: "auto" }}>
                  {packagingComponents.length > 0 ? (
                    <RecipePackagingComponentTable
                      disabled={!includePackaging}
                      effectType={effectTypes.ghgPerServing}
                      packagingComponents={packagingComponents}
                    />
                  ) : (
                    <RecipeNotPackagedSection />
                  )}
                </div>
              </div>

              <div className="col-4 align-self-stretch d-flex flex-column">
                <PackagingComponentImpactSection
                  disabled={!includePackaging}
                  effectType={effectTypes.ghgPerServing}
                  ghgPerServingValue={packagingComponentsGhgPerServingValue}
                  impactPercentageOfTotalRecipe={
                    packagingImpactPercentageOfTotalRecipe
                  }
                />
              </div>
            </div>
          </Panel>
        )}

        {hasEffects ? (
          newLifeCycleImpactChart ? (
            <LifeCycleImpacts
              impact={impact}
              nullProductWeightErrorMessage={nullProductWeightErrorMessage()}
              productWeightKgPerServing={productWeightKgPerPortion()}
            />
          ) : (
            <LifeCycleChartCard impact={impact} />
          )
        ) : null}
        <ImpactComparison
          impact={impact}
          includePackaging={includePackaging}
          recipe={recipe}
        />
      </div>
      {impact.isStale && <UpdatingProductSpinnerCard />}
    </div>
  );
}

function UpdatingProductSpinnerCard() {
  return (
    <div className="RecipeBreakdown_UpdatingProductSpinnerCard_Container">
      <Card className="RecipeBreakdown_UpdatingProductSpinnerCard">
        <Spinner />
        <h4>
          <FormattedMessage
            id="components/recipes/RecipeBreakdown:calculatingImpact"
            defaultMessage="Calculating Impact..."
          />
        </h4>
      </Card>
    </div>
  );
}

interface MissingSummaryExplanationProps {
  errors: Array<{ kind: ImpactCalculatorErrorKind }>;
  onEdit: () => void;
  recipe: Recipe;
}

function MissingSummaryExplanation(props: MissingSummaryExplanationProps) {
  const { errors, onEdit, recipe } = props;

  const foodManufacturerOrganization = useFoodManufacturerOrganization();

  const editButtonProperties = Buttons.useEditButtonProperties({
    onEdit,
    recipe,
  });

  const iconClass = "mt-3 mb-3";
  const iconWidth = "4.4em";

  const commonTags = {
    contact: (chunks: React.ReactNode) => (
      <ContactUsButton.Recipe recipe={recipe}>{chunks}</ContactUsButton.Recipe>
    ),
    actionButton:
      editButtonProperties === null ? null : (
        <PrimaryButton onClick={editButtonProperties.onClick}>
          {editButtonProperties.label}
        </PrimaryButton>
      ),
    header: (chunks: React.ReactNode) => (
      <p className="mb-1">
        <strong>{chunks}</strong>
      </p>
    ),
    muted: (chunks: React.ReactNode) => (
      <p className="text-muted mb-2">{chunks}</p>
    ),
    strong: (chunks: React.ReactNode) => <strong>{chunks}</strong>,
    quote: (chunks: React.ReactNode) => (
      <>
        {'"'}
        {chunks}
        {'"'}
      </>
    ),
  };

  const noRecipeImpactJustYet = (
    <FormattedMessage
      id="components/recipes/RecipeBreakdown:noRecipeImpactJustYet"
      defaultMessage="
        <header>
          We can't calculate the impact of your product just yet...
        </header>
      "
      values={commonTags}
    />
  );

  const commonComponents = { ...commonTags, noRecipeImpactJustYet };

  if (
    hasError(
      errors,
      ImpactCalculatorErrorKind.RECIPE_INGREDIENT_NOT_LINKED_TO_FOOD_CLASS
    )
  ) {
    return (
      <div className="mb-3">
        <PendingImpact className={iconClass} width={iconWidth} />
        <FormattedMessage
          id="components/recipes/RecipeBreakdown:recipeIngredientNotLinkedToFoodClass"
          defaultMessage="
            {noRecipeImpactJustYet}
            
            <muted>
              Your product contains a <strong>new ingredient {unlinkedIngredientTooltip}</strong>. You will
              be able to see the product's impact once Foodsteps has added it to
              the database.
            </muted>

            <contact>Contact us for support</contact>
          "
          values={{
            ...commonComponents,
            unlinkedIngredientTooltip: (
              <QuestionMarkTooltipOverlay placement="top">
                <FormattedMessage
                  id="components/recipes/RecipeBreakdown:unlinkedIngredientTooltipOverlay"
                  defaultMessage="
                          A new ingredient is indicated by a {brokenLink}
                          -icon. Once created by you, it typically takes us a few days to
                          add it to the database.
                        "
                  values={{
                    brokenLink: (
                      <BrokenLink
                        className="RecipeIngredientLinking__unlinked mr-n1"
                        width="1em"
                      />
                    ),
                  }}
                />
              </QuestionMarkTooltipOverlay>
            ),
          }}
        />
      </div>
    );
  } else if (
    !foodManufacturerOrganization &&
    hasError(
      errors,
      ImpactCalculatorErrorKind.RECIPE_HAS_INCOMPLETE_COOKING_PENALTIES
    )
  ) {
    return (
      <div className="mb-3">
        <PendingImpact className={iconClass} width={iconWidth} />
        <FormattedMessage
          id="components/recipes/RecipeBreakdown:recipeHasIncompleteCookingPenalties"
          defaultMessage="
        {noRecipeImpactJustYet}

        <muted>
          Your product is <strong>missing cooking instructions</strong>. You
          will be able to see the product's impact once you have added some.
        </muted>

        {actionButton}
      "
          values={commonComponents}
        />
      </div>
    );
  } else if (
    !foodManufacturerOrganization &&
    hasError(
      errors,
      ImpactCalculatorErrorKind.RECIPE_HAS_INCOMPLETE_POST_PREPARATION_STORAGE_PENALTIES
    )
  ) {
    return (
      <div className="mb-3">
        <PendingImpact className={iconClass} width={iconWidth} />
        <FormattedMessage
          id="components/recipes/RecipeBreakdown:recipeHasIncompletePostPreparationStoragePenalties"
          defaultMessage="
      {noRecipeImpactJustYet}

      <muted>
        Your product is <strong>missing  post-preparation storage instructions</strong>.
        You will be able to see the product's impact once you have added some.
      </muted>

      {actionButton}
    "
          values={commonComponents}
        />
      </div>
    );
  } else if (
    hasError(
      errors,
      ImpactCalculatorErrorKind.RECIPE_INGREDIENT_MISSING_WEIGHT_OF_EACH
    )
  ) {
    return (
      <div className="mb-3">
        <PendingImpact className={iconClass} width={iconWidth} />
        <FormattedMessage
          id="components/recipes/RecipeBreakdown:recipeIngredientMissingWeightOfEach"
          defaultMessage="
          {noRecipeImpactJustYet}

          <muted>
            Your product uses the <strong><quote>each</quote> measure with an ingredient
            for which that measure does not make sense</strong>. Swap it out for a weight
            or volumetric measure to see your product's impact.
          </muted>

          {actionButton}
          "
          values={commonComponents}
        />
      </div>
    );
  } else if (
    hasError(
      errors,
      ImpactCalculatorErrorKind.RECIPE_INGREDIENT_LINKED_TO_UNVALIDATED_FOOD_CLASS
    )
  ) {
    return (
      <div className="mb-3">
        <PendingImpact className={iconClass} width={iconWidth} />
        <FormattedMessage
          id="components/recipes/RecipeBreakdown:recipeIngredientLinkedToUnvalidatedFoodClass"
          defaultMessage="
          {noRecipeImpactJustYet}
          
          <muted>
            Your product contains an <strong>ingredient that Foodsteps can't confirm
            the impact of yet {unvalidatedIngredientTooltip}</strong>. You will be able to
            see the product's impact once Foodsteps has added it to the database.
          </muted>

          {actionButton}
        "
          values={{
            ...commonComponents,
            unvalidatedIngredientTooltip: (
              <QuestionMarkTooltipOverlay placement="top">
                <FormattedMessage
                  id="components/recipes/RecipeBreakdown:unvalidatedIngredientTooltipOverlay"
                  defaultMessage="
                        Foodsteps needs to validate the life cycle data of this ingredient
                        before its impact can be calculated.
                      "
                />
              </QuestionMarkTooltipOverlay>
            ),
          }}
        />
      </div>
    );
  } else {
    return (
      <div className="mb-3">
        <Error className={iconClass} width={iconWidth} />
        <FormattedMessage
          id="components/recipes/RecipeBreakdown:pendingMessage"
          defaultMessage="
          <header>
            Something went wrong...
          </header>

          <muted>Try editing your product to find the source of the error, or contact Foodsteps for help.</muted>

          <contact>Contact support</contact>
        "
          values={commonComponents}
        />
      </div>
    );
  }
}

function hasError(
  errors: Array<{ kind: ImpactCalculatorErrorKind }>,
  errorKind: ImpactCalculatorErrorKind
) {
  return errors.some((error) => error.kind === errorKind);
}

RecipeBreakdown.fragments = {
  impactRatingInfo: gql`
    fragment RecipeBreakdown_EffectTypeImpactRatingInfo on EffectTypeImpactRatingInfo {
      ...ImpactRatingExplanation_EffectTypeImpactRatingInfo
    }

    ${ImpactRatingExplanation.fragments.impactRatingInfo}
  `,

  impact: gql`
    fragment RecipeBreakdown_RecipeImpact on RecipeImpact {
      effects {
        ghgPerKg
        ghgPerRecipe
        ghgPerRootRecipeServing
      }
      errors {
        kind
      }
      impactRating
      isStale

      ingredients {
        ...IngredientImpactChart_RecipeIngredientImpact
      }
      ...ImpactComparison_RecipeImpact
      ...ImpactSummary_RecipeImpact
      ...ImpactRatingExplanation_RecipeImpact
      ...LifeCycleImpacts_RecipeImpact
      ...IngredientImpactBreakdown_RecipeImpact
      ...RecipePageCarbonLabel_RecipeImpact
    }
    ${IngredientImpactChart.fragments.recipeIngredientImpact}
    ${ImpactComparison.fragments.recipeImpact}
    ${ImpactSummary.fragments.recipeImpact}
    ${ImpactRatingExplanation.fragments.recipeImpact}
    ${LifeCycleImpacts.fragments.recipeImpact}
    ${IngredientImpactBreakdown.fragments.recipeImpact}
    ${RecipePageCarbonLabel.fragments.recipeImpact}
  `,

  packagingComponent: gql`
    fragment RecipeBreakdown_PackagingComponentV2 on PackagingComponentV2 {
      ghgMagnitude
      ...RecipePackagingComponentTable_PackagingComponentV2
    }

    ${RecipePackagingComponentTable.fragments.packagingComponent}
  `,

  recipe: gql`
    fragment RecipeBreakdown_Recipe on Recipe {
      id
      isHotDrink
      viewerHasPermissionUpdate
      collections {
        id
      }
      packagingComponentsV2 {
        packagingComponent {
          ghgMagnitude
          ...RecipePackagingComponentTable_PackagingComponentV2
        }
      }
      productMassQuantity
      productMassUnit
      ...IngredientImpactBreakdown_Recipe
      ...RecipePageCarbonLabel_Recipe
    }
    ${RecipePackagingComponentTable.fragments.packagingComponent}
    ${IngredientImpactBreakdown.fragments.recipe}
    ${RecipePageCarbonLabel.fragments.recipe}
  `,
};
