import { IntlShape } from "react-intl";

// All ghg equivalents are in kg CO2e
// Land - m^2, Water - litres
// N.B. whilst the source for ghg equivalents are refrenced
// the land and water_use conversions should be checked

export interface RelatableEquivalent {
  title: (intl: IntlShape) => string;
  value: number;
}

export const relatableEquivalents = {
  ghg: {
    milesDrivenAvrgCar: {
      value: 0.23,
      title: (intl: IntlShape) =>
        intl.formatMessage({
          id: "domain/relatableEquivalents:ghg/milesDrivenAvrgCar/title",
          defaultMessage: "miles driven",
        }),
    },
    flightLondonToMalaga: {
      value: 320,
      title: (intl: IntlShape) =>
        intl.formatMessage({
          id: "domain/relatableEquivalents:ghg/flightLondonToMalaga/title",
          defaultMessage: "flights to Malaga",
        }),
    },
    smartphoneCharge: {
      value: 0.00784,
      title: (intl: IntlShape) =>
        intl.formatMessage({
          id: "domain/relatableEquivalents:ghg/smartphoneCharge/title",
          defaultMessage: "smartphone charges",
        }),
    },
  },
};
