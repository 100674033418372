import gql from "graphql-tag";
import { useIntl } from "react-intl";

import { ImpactRating } from "../../__generated__/globalTypes";
import EffectType, { effectTypes } from "../../domain/EffectType";
import {
  impactRatingToLetter,
  useImpactRatingToLongName,
} from "../../domain/impactRatings";
import * as comparators from "../../util/comparators";
import sort from "../../util/sort";
import { infoCol } from "../graphs/colors";
import {
  ImpactRatingExplanation_EffectTypeImpactRatingInfo as EffectTypeImpactRatingInfo,
  ImpactRatingExplanation_RecipeImpact,
} from "./ImpactRatingExplanation.graphql";

interface ImpactRatingExplanationProps {
  effectType: EffectType;
  impact: ImpactRatingExplanation_RecipeImpact;
  impactRatingInfos: Array<EffectTypeImpactRatingInfo>;
}

const width = 320;
const height = 240;
const lineWidth = 2;
const paddingProportion = 0.2;
const ratingBarWidth = 20;
const tickWidth = 20;
const tickPadding = 10;
const labelPadding = 20;
const labelX = Math.floor((width + ratingBarWidth) / 2 + labelPadding);

export default function ImpactRatingExplanation(
  props: ImpactRatingExplanationProps
) {
  const { effectType, impact, impactRatingInfos } = props;

  const recipeGhgPerUnit =
    effectType === effectTypes.ghgPerKg
      ? impact.effects?.ghgPerKg ?? null
      : impact.effects?.ghgPerRootRecipeServing ?? null;

  if (recipeGhgPerUnit === null) {
    return null;
  }

  const impactRatingInfosHighToLow = sort(
    impactRatingInfos,
    comparators.map(
      (info) => info.ghgLowerBound,
      comparators.nullsLast(comparators.reversed(comparators.number))
    )
  );

  let impactRatingIndex = impactRatingInfosHighToLow.findIndex(
    (info) =>
      info.ghgLowerBound != null && info.ghgLowerBound <= recipeGhgPerUnit
  );
  if (impactRatingIndex === -1) {
    impactRatingIndex = impactRatingInfosHighToLow.length - 1;
  }

  const ratingLower = indexOrNull(
    impactRatingInfosHighToLow,
    impactRatingIndex + 1
  );
  const rating = indexOrNull(impactRatingInfosHighToLow, impactRatingIndex);
  const ratingHigher = indexOrNull(
    impactRatingInfosHighToLow,
    impactRatingIndex - 1
  );

  if (rating === null) {
    return null;
  }

  const ghgPerUnitToY = (ghgPerUnit: number) => {
    const upperValue =
      ratingHigher === null ? recipeGhgPerUnit : ratingHigher.ghgLowerBound!!;

    const lowerValue = rating.ghgLowerBound ?? 0;

    const topValue = upperValue + (upperValue - lowerValue) * paddingProportion;

    const bottomValue =
      ratingLower === null
        ? lowerValue
        : lowerValue - (upperValue - lowerValue) * paddingProportion;

    const bottomOffset = ratingLower === null ? 8 : 0;

    const proportion = (ghgPerUnit - bottomValue) / (topValue - bottomValue);

    return Math.floor((height - bottomOffset) * (1 - proportion));
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
    >
      {ratingHigher !== null && (
        <VisibleImpactRating
          minY={ghgPerUnitToY(ratingHigher.ghgLowerBound!!)}
          impactRating={ratingHigher.impactRating}
        />
      )}

      <VisibleImpactRating
        maxY={
          ratingHigher === null
            ? undefined
            : ghgPerUnitToY(ratingHigher.ghgLowerBound!!)
        }
        minY={ghgPerUnitToY(rating.ghgLowerBound ?? 0)}
        impactRating={rating.impactRating}
      />

      {ratingLower !== null && (
        <VisibleImpactRating
          maxY={ghgPerUnitToY(rating.ghgLowerBound!!)}
          impactRating={ratingLower.impactRating}
        />
      )}

      {ratingHigher !== null && ratingHigher.ghgLowerBound !== null && (
        <BoundaryLabel
          effectType={effectType}
          ghgPerUnit={ratingHigher.ghgLowerBound}
          y={ghgPerUnitToY(ratingHigher.ghgLowerBound)}
        />
      )}

      <BoundaryLabel
        effectType={effectType}
        ghgPerUnit={rating.ghgLowerBound ?? 0}
        y={ghgPerUnitToY(rating.ghgLowerBound ?? 0)}
      />

      <RecipeImpact
        effectType={effectType}
        ghgPerUnit={recipeGhgPerUnit}
        y={ghgPerUnitToY(recipeGhgPerUnit)}
      />
    </svg>
  );
}

interface VisibleImpactRatingProps {
  impactRating: ImpactRating;
  maxY?: number | null;
  minY?: number | null;
}

function VisibleImpactRating(props: VisibleImpactRatingProps) {
  const { impactRating } = props;

  const maxY = props.maxY ?? -10;
  const minY = props.minY ?? height + 10;
  const barHeight = minY - maxY;

  return (
    <>
      <RatingBar y={maxY} height={barHeight} impactRating={impactRating} />
      <RatingLabel impactRating={impactRating} y={(maxY + minY) / 2} />
    </>
  );
}

function indexOrNull<T>(array: Array<T>, index: number): T | null {
  return array[index] ?? null;
}

interface RatingBackgroundProps {
  height: number;
  impactRating: ImpactRating;
  y: number;
}

function RatingBar(props: RatingBackgroundProps) {
  const { height, impactRating, y } = props;

  const fill = `var(--label-${impactRatingToLetter(
    impactRating
  ).toUpperCase()})`;

  return (
    <rect
      x={(width - ratingBarWidth) / 2 + 0.5}
      y={y}
      width={ratingBarWidth - 1}
      height={height}
      rx={5}
      fill={fill}
      stroke="#999"
      strokeWidth={1}
    />
  );
}

interface RatingLabelProps {
  impactRating: ImpactRating;
  y: number;
}

const mediumFontWeight = getComputedStyle(
  document.documentElement
).getPropertyValue("--font-weight-medium");

function RatingLabel(props: RatingLabelProps) {
  const { impactRating, y } = props;
  const fontSize = 16;

  const impactRatingToLongName = useImpactRatingToLongName;

  return (
    <text
      fill={infoCol}
      fontSize={fontSize}
      fontWeight={mediumFontWeight}
      x={labelX}
      y={y + fontSize / 2}
    >
      {impactRatingToLetter(impactRating)} -{" "}
      {impactRatingToLongName(impactRating)}
    </text>
  );
}

interface RecipeImpactProps {
  effectType: EffectType;
  ghgPerUnit: number;
  y: number;
}

function RecipeImpact(props: RecipeImpactProps) {
  const { effectType, ghgPerUnit, y } = props;
  const intl = useIntl();

  const unitFontSize = 12;
  const rightX = (width - ratingBarWidth) / 2 - lineWidth / 2 - 3;
  const tickX = Math.floor(rightX - tickWidth);
  const labelX = tickX - tickPadding;
  const textAnchor = "end";
  const arrowHeadLength = 6;

  return (
    <>
      <rect
        x={tickX}
        y={y - lineWidth / 2}
        width={tickWidth}
        height={lineWidth}
        fill="#000"
      />
      <line
        x1={rightX}
        y1={y}
        x2={rightX - arrowHeadLength}
        y2={y + arrowHeadLength}
        stroke="#000"
        strokeWidth={lineWidth}
        strokeLinecap="round"
      />
      <line
        x1={rightX}
        y1={y}
        x2={rightX - arrowHeadLength}
        y2={y - arrowHeadLength}
        stroke="#000"
        strokeWidth={lineWidth}
        strokeLinecap="round"
      />
      <text
        fill={infoCol}
        x={labelX}
        y={y - 4}
        textAnchor={textAnchor}
        fontSize={24}
        fontWeight={mediumFontWeight}
      >
        {ghgPerUnit.toFixed(effectType.decimals)}
      </text>
      <text
        fill={infoCol}
        x={labelX}
        y={y + 4 + unitFontSize}
        textAnchor={textAnchor}
        fontSize={unitFontSize}
      >
        {effectType.unitString(intl)}
      </text>
    </>
  );
}

interface TickLabelProps {
  effectType: EffectType;
  ghgPerUnit: number;
  y: number;
}

function BoundaryLabel(props: TickLabelProps) {
  const { effectType, ghgPerUnit, y } = props;

  const fontSize = 12;

  return (
    <text fill={infoCol} x={labelX} y={y + fontSize / 2} fontSize={fontSize}>
      {ghgPerUnit.toFixed(effectType.decimals)}
    </text>
  );
}

ImpactRatingExplanation.fragments = {
  impactRatingInfo: gql`
    fragment ImpactRatingExplanation_EffectTypeImpactRatingInfo on EffectTypeImpactRatingInfo {
      ghgLowerBound
      impactRating
    }
  `,

  recipeImpact: gql`
    fragment ImpactRatingExplanation_RecipeImpact on RecipeImpact {
      effects {
        ghgPerKg
        ghgPerRootRecipeServing
      }
    }
  `,
};
