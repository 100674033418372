import { EffectType, ImpactRating } from "../../__generated__/globalTypes";
import FoodServiceImpactSummary from "./FoodServiceImpactSummary";

const stories = {
  name: "FoodServiceImpactSummary",
  render: () => (
    <FoodServiceImpactSummary
      effectTypeForImpactRating={EffectType.GHG_PER_KG}
      impact={{
        impactRating: ImpactRating.HIGH,
        effects: {
          ghgPerKg: 10,
          ghgPerRootRecipeServing: 4,
          landUsePerRootRecipeServing: 1,
          landUsePerKg: 2,
          waterUsePerRootRecipeServing: 3.5,
          waterUsePerKg: 2.1,
        },
        errors: [],
      }}
      impactRatingInfos={[
        { ghgLowerBound: 0, impactRating: ImpactRating.VLOW },
        { ghgLowerBound: 1, impactRating: ImpactRating.LOW },
        { ghgLowerBound: 2, impactRating: ImpactRating.MEDIUM },
        { ghgLowerBound: 3, impactRating: ImpactRating.HIGH },
        { ghgLowerBound: 4, impactRating: ImpactRating.VHIGH },
      ]}
      recipe={{ isHotDrink: true, id: 1 }}
      showExportButton={true}
    />
  ),
};

export default stories;
