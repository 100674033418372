import { ImpactUnit } from "../../__generated__/globalTypes";
import { qualitativeDataColors } from "../graphs/colors";
import { ROOT_NODE_NAME } from "../utils/Sunburst";
import EndMile from "../utils/Vectors/illustrations/EndMile";
import Farm from "../utils/Vectors/illustrations/Farm";
import Packaging from "../utils/Vectors/illustrations/Packaging";
import Processing from "../utils/Vectors/illustrations/Processing";
import Retail from "../utils/Vectors/illustrations/Retail";
import Transport from "../utils/Vectors/illustrations/Transport";
import Waste from "../utils/Vectors/illustrations/Waste";
import { LifeCycleStage } from "./LifeCycleImpacts";
import { LifeCycleImpacts_RecipeImpact } from "./LifeCycleImpacts.graphql";

export function getTestLifeCycleStageRoot() {
  const root = new LifeCycleStage(ROOT_NODE_NAME, null, 100);

  const processing = new LifeCycleStage(
    "Processing",
    root,
    40,
    Processing,
    qualitativeDataColors[0]
  );
  new LifeCycleStage("Processing 1", processing, 10);
  new LifeCycleStage("Processing 2", processing, 30);
  const farm = new LifeCycleStage(
    "Farm",
    root,
    20,
    Farm,
    qualitativeDataColors[1]
  );
  const farm1 = new LifeCycleStage(
    "Farm 1",
    farm,
    16,
    Farm,
    qualitativeDataColors[1]
  );
  new LifeCycleStage("Farm 2", farm, 4, Farm, qualitativeDataColors[1]);
  new LifeCycleStage("Farm 1A", farm1, 10, Farm, qualitativeDataColors[1]);
  new LifeCycleStage("Farm 1B", farm1, 6, Farm, qualitativeDataColors[1]);
  new LifeCycleStage("Retail", root, 15, Retail, qualitativeDataColors[2]);
  new LifeCycleStage(
    "Packaging",
    root,
    10,
    Packaging,
    qualitativeDataColors[3]
  );
  new LifeCycleStage("End mile", root, 5, EndMile, qualitativeDataColors[4]);
  new LifeCycleStage("Cooking", root, 5, EndMile, qualitativeDataColors[5]);
  new LifeCycleStage("Transport", root, 3, Transport, qualitativeDataColors[6]);
  new LifeCycleStage("Waste", root, 2, Waste, qualitativeDataColors[7]);

  return root;
}

export function getTestImpact(): LifeCycleImpacts_RecipeImpact {
  return {
    effects: {
      stages: [
        {
          codename: "ghg",
          impactPerKg: 0.9912451947466508,
          impactPerRecipe: 0.9912451947466508,
          impactPerRootRecipeServing: 0.9912451947466508,
          impactUnit: ImpactUnit.CO2E,
        },
        {
          codename: "ghg_luc_burn",
          impactPerKg: 0.0034001199694837126,
          impactPerRecipe: 0.0034001199694837126,
          impactPerRootRecipeServing: 0.0034001199694837126,
          impactUnit: ImpactUnit.CO2E,
        },
        {
          codename: "ghg_luc_c_stock",
          impactPerKg: 0.016755772779685683,
          impactPerRecipe: 0.016755772779685683,
          impactPerRootRecipeServing: 0.016755772779685683,
          impactUnit: ImpactUnit.CO2E,
        },
        {
          codename: "ghg_feed",
          impactPerKg: 0,
          impactPerRecipe: 0,
          impactPerRootRecipeServing: 0,
          impactUnit: ImpactUnit.CO2E,
        },
        {
          codename: "ghg_farm",
          impactPerKg: 0.47895293534791983,
          impactPerRecipe: 0.47895293534791983,
          impactPerRootRecipeServing: 0.47895293534791983,
          impactUnit: ImpactUnit.CO2E,
        },
        {
          codename: "ghg_processing",
          impactPerKg: 0.040855682692326624,
          impactPerRecipe: 0.040855682692326624,
          impactPerRootRecipeServing: 0.040855682692326624,
          impactUnit: ImpactUnit.CO2E,
        },
        {
          codename: "ghg_tran_str",
          impactPerKg: 0.12305286739251203,
          impactPerRecipe: 0.12305286739251203,
          impactPerRootRecipeServing: 0.12305286739251203,
          impactUnit: ImpactUnit.CO2E,
        },
        {
          codename: "ghg_packaging",
          impactPerKg: 0.16287378224602678,
          impactPerRecipe: 0.16287378224602678,
          impactPerRootRecipeServing: 0.16287378224602678,
          impactUnit: ImpactUnit.CO2E,
        },
        {
          codename: "ghg_retail",
          impactPerKg: 0.04510511266567869,
          impactPerRecipe: 0.04510511266567869,
          impactPerRootRecipeServing: 0.04510511266567869,
          impactUnit: ImpactUnit.CO2E,
        },
        {
          codename: "ghg_end_mile",
          impactPerKg: 0,
          impactPerRecipe: 0,
          impactPerRootRecipeServing: 0,
          impactUnit: ImpactUnit.CO2E,
        },
        {
          codename: "ghg_end_of_life",
          impactPerKg: 0.018085393401327098,
          impactPerRecipe: 0.018085393401327098,
          impactPerRootRecipeServing: 0.018085393401327098,
          impactUnit: ImpactUnit.CO2E,
        },
        {
          codename: "ghg_cooking",
          impactPerKg: 0,
          impactPerRecipe: 0,
          impactPerRootRecipeServing: 0,
          impactUnit: ImpactUnit.CO2E,
        },
        {
          codename: "ghg_loss",
          impactPerKg: 0.177251516788697,
          impactPerRecipe: 0.177251516788697,
          impactPerRootRecipeServing: 0.177251516788697,
          impactUnit: ImpactUnit.CO2E,
        },
      ],
      ghgPerKg: 1,
      ghgPerRootRecipeServing: 1,
      landUsePerKg: 1,
      landUsePerRootRecipeServing: 1,
      waterUsePerKg: 1,
      waterUsePerRootRecipeServing: 1,
    },
    ingredients: [],
    weightKgPerServing: 2,
    unavailableWaterUse: false,
    unavailableLandUse: false,
  };
}
