import classNames from "classnames";
import { FormattedMessage } from "react-intl";

import "./CarbonLabelsSalesPrompt.css";
import { usePages } from "../pages";
import { PrimaryButtonLink } from "../utils/Button";
import { FilledCheckmark } from "../utils/Vectors";
import CarbonLabel from "./carbon-label/CarbonLabel";

export default function CarbonLabelsSalesPrompt() {
  const pages = usePages();
  return (
    <div className="foodsteps-labels-sales-prompt-container">
      <div className="medium-font mb-1 text-center">
        <FormattedMessage
          id="components/labels/CarbonLabelsSalesPrompt:title"
          defaultMessage="Carbon Labels"
        />
      </div>

      <Reason className="pb-1">
        <div className="reason-with-inline-label">
          <p className="mb-0">
            <FormattedMessage
              id="components/labels/CarbonLabelsSalesPrompt:reasonStandOut"
              defaultMessage="Stand out as a carbon conscious company!"
            />
          </p>
          <div>
            <CarbonLabel
              impactRating="MEDIUM"
              type="titledRatingScale"
              width={104}
            />
          </div>
        </div>
      </Reason>

      <Reason className="pb-1">
        <FormattedMessage
          id="components/labels/CarbonLabelsSalesPrompt:reasonMeetCarbonTargets"
          defaultMessage="Help both you and your customers meet
            your carbon targets."
        />
      </Reason>

      <Reason className="pb-1">
        <FormattedMessage
          id="components/labels/CarbonLabelsSalesPrompt:reasonInternationalTargets"
          defaultMessage="Use the Foodsteps rating system to align
            to international targets."
        />
      </Reason>

      <Reason>
        <FormattedMessage
          id="components/labels/CarbonLabelsSalesPrompt:reasonLabels"
          defaultMessage="Labels for packaging, digital and physical
            menus and apps."
        />
      </Reason>

      <div className="mt-3 text-center">
        <PrimaryButtonLink
          to={pages.Plans.url}
          className="w-100 d-flex justify-content-center"
        >
          <FormattedMessage
            id="components/labels/CarbonLabelsSalesPrompt:explorePlansButton"
            defaultMessage="Explore our plans"
          />
        </PrimaryButtonLink>
      </div>
    </div>
  );
}

interface ReasonProps {
  children: React.ReactNode;
  className?: string;
}

function Reason(props: ReasonProps) {
  const { children, className = "" } = props;

  return (
    <div className={classNames("d-flex", className)}>
      <div>
        <FilledCheckmark width="20px" />
      </div>
      <div className="small w-100 pl-2">{children}</div>
    </div>
  );
}
