import classNames from "classnames";
import gql from "graphql-tag";
import { useState } from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";

import { RecipeOrder } from "../../__generated__/globalTypes";
import useCollections from "../../data-store/useCollections";
import { effectTypes } from "../../domain/EffectType";
import {
  useAdditionalImpactCategories,
  useTags,
} from "../../services/useOrganizationFeatures";
import { RecipesListPageQueryParams } from "../pages";
import ProductFilterSelect from "../product-filter/ProductFilterSelect";
import StatusDisplay from "../StatusDisplay";
import ManageTagsButton from "../tags/ManageTagsButton";
import Checkbox from "../utils/Checkbox";
import SearchBox from "../utils/SearchBox";
import Select from "../utils/Select";
import useStateWithDebouncedUpdate from "../utils/useStateWithDebouncedUpdate";
import { AttentionTriangle } from "../utils/Vectors";
import { CollectionToSelect } from "./RecipeQueryControls.graphql";
import SystemBoundaryText from "./SystemBoundaryText";
import useRecipeLabel from "./useRecipeLabel";

import "./RecipeQueryControls.css";

export type OrderBy =
  | RecipeOrder.NAME_ASC
  | RecipeOrder.CACHED_GHG_PER_KG_ASC
  | RecipeOrder.CACHED_GHG_PER_KG_DESC
  | RecipeOrder.CACHED_GHG_PER_SERVING_ASC
  | RecipeOrder.CACHED_GHG_PER_SERVING_DESC
  | RecipeOrder.CREATED_AT_ASC
  | RecipeOrder.CREATED_AT_DESC;

const orderByOptions: Array<OrderBy> = [
  RecipeOrder.NAME_ASC,
  RecipeOrder.CACHED_GHG_PER_SERVING_ASC,
  RecipeOrder.CACHED_GHG_PER_SERVING_DESC,
  RecipeOrder.CACHED_GHG_PER_KG_ASC,
  RecipeOrder.CACHED_GHG_PER_KG_DESC,
  RecipeOrder.CREATED_AT_ASC,
  RecipeOrder.CREATED_AT_DESC,
];

function recipeOrderLabel(intl: IntlShape, orderBy: OrderBy): string {
  switch (orderBy) {
    case RecipeOrder.NAME_ASC:
      return intl.formatMessage({
        id: "components/recipes/RecipeQueryControls:order/nameAsc/label",
        defaultMessage: "Name",
      });

    case RecipeOrder.CACHED_GHG_PER_KG_ASC:
      return intl.formatMessage({
        id: "components/recipes/RecipeQueryControls:order/ghgPerKgAsc/label",
        defaultMessage: "Rating: Low to High",
      });

    case RecipeOrder.CACHED_GHG_PER_KG_DESC:
      return intl.formatMessage({
        id: "components/recipes/RecipeQueryControls:order/ghgPerKgDesc/label",
        defaultMessage: "Rating: High to Low",
      });

    case RecipeOrder.CACHED_GHG_PER_SERVING_ASC:
      return intl.formatMessage(
        {
          id: "components/recipes/RecipeQueryControls:order/ghgPerServingAsc/label",
          defaultMessage: "{effectType}: Low to High",
        },
        { effectType: effectTypes.ghgPerServing.title(intl) }
      );

    case RecipeOrder.CACHED_GHG_PER_SERVING_DESC:
      return intl.formatMessage(
        {
          id: "components/recipes/RecipeQueryControls:order/ghgPerServingDesc/label",
          defaultMessage: "{effectType}: High to Low",
        },
        { effectType: effectTypes.ghgPerServing.title(intl) }
      );

    case RecipeOrder.CREATED_AT_ASC:
      return intl.formatMessage({
        id: "components/recipes/RecipeQueryControls:order/createdAtAsc/label",
        defaultMessage: "Oldest to Newest",
      });

    case RecipeOrder.CREATED_AT_DESC:
      return intl.formatMessage({
        id: "components/recipes/RecipeQueryControls:order/createdAtDesc/label",
        defaultMessage: "Newest to Oldest",
      });
  }
}

export function parseRecipeOrder(rawOrderBy: string): OrderBy | null {
  return (orderByOptions as Array<string>).includes(rawOrderBy)
    ? (rawOrderBy as OrderBy)
    : null;
}

interface RecipeQueryControlsProps {
  disabled: boolean;
  onChange: (value: Partial<RecipesListPageQueryParams>) => void;
  selectAllCheckbox: React.ReactNode;
  selectCollection: {
    placeholder: string;
  } | null;
  showFilterToRequiresAttention: boolean;
  showSearch: boolean;
  showManageTagsButton: boolean;
  value: RecipesListPageQueryParams;
}

export default function RecipeQueryControls(props: RecipeQueryControlsProps) {
  const {
    disabled,
    onChange,
    selectAllCheckbox,
    showFilterToRequiresAttention,
    showSearch,
    selectCollection,
    showManageTagsButton,
    value,
  } = props;

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const recipeLabel = useRecipeLabel();
  const additionalImpactCategories = useAdditionalImpactCategories();
  const hasTagsFeature = useTags();

  const [searchTermText, setSearchTermText] = useStateWithDebouncedUpdate({
    initialValue: value.searchTerm,
    update: (newValue: string) => onChange({ searchTerm: newValue }),
  });

  const { collectionsStatus } = useCollections();

  return (
    <div
      className={classNames("recipe-query-controls", {
        RecipeQueryControls_Disabled: disabled,
      })}
    >
      <div className="d-flex top-filter-row">
        {showSearch && (
          <RecipeSearchBox
            onChange={(searchTerm) => setSearchTermText(searchTerm)}
            value={searchTermText}
          />
        )}
        <OrderByDropdown
          onChange={(orderBy) => onChange({ orderBy })}
          value={value.orderBy}
        />
        {!hasTagsFeature && selectCollection && (
          <StatusDisplay status={collectionsStatus}>
            {(collections) => (
              <SelectCollection
                collections={collections}
                placeholder={selectCollection.placeholder}
                selectedCollection={
                  value.filterToSelectedCollectionIds &&
                  value.filterToSelectedCollectionIds.length > 0
                    ? collections.filter(
                        (collection) =>
                          collection.id ===
                          (value.filterToSelectedCollectionIds
                            ? value.filterToSelectedCollectionIds[0]
                            : null)
                      )[0]
                    : null
                }
                setSelectedCollection={(
                  selectedCollection: { id: number } | null
                ) =>
                  onChange({
                    filterToSelectedCollectionIds: selectedCollection
                      ? [selectedCollection.id]
                      : [],
                  })
                }
              />
            )}
          </StatusDisplay>
        )}
        {hasTagsFeature && selectCollection && (
          <div className="filter-wrapper">
            <ProductFilterSelect
              showGeneralStatusSection={showFilterToRequiresAttention}
              showManageTagsButton={showManageTagsButton}
              setFilters={(
                filterToSelectedCollectionIds: Array<number>,
                filterToRequiresClientAttention: boolean,
                intersectCollections: boolean
              ) =>
                onChange({
                  filterToSelectedCollectionIds,
                  filterToRequiresClientAttention,
                  intersectCollections,
                })
              }
              filters={{
                filterToSelectedCollectionIds:
                  value.filterToSelectedCollectionIds,
                filterToRequiresClientAttention:
                  value.filterToRequiresClientAttention,
                intersectCollections: value.intersectCollections,
              }}
              menuIsOpen={menuIsOpen}
              setMenuIsOpen={setMenuIsOpen}
            />
          </div>
        )}
        {hasTagsFeature && showManageTagsButton && (
          <ManageTagsButton
            onCollectionDeleted={(deletedCollectionId) => {
              const withoutDeletedCollection = [
                ...value.filterToSelectedCollectionIds,
              ].filter(
                (filteredCollectionId) =>
                  filteredCollectionId !== deletedCollectionId
              );
              if (
                withoutDeletedCollection.length !==
                value.filterToSelectedCollectionIds.length
              ) {
                onChange({
                  filterToSelectedCollectionIds: withoutDeletedCollection,
                  filterToRequiresClientAttention:
                    value.filterToRequiresClientAttention,
                  intersectCollections: value.intersectCollections,
                });
              }
            }}
            withText={false}
          />
        )}
      </div>
      {additionalImpactCategories && (
        <div className="RecipeQueryControls_SystemBoundaryText my-3">
          <div>
            <SystemBoundaryText />
          </div>
        </div>
      )}
      <div className="d-flex my-3 flex-row">
        <div className="mr-3">{selectAllCheckbox}</div>
        {!hasTagsFeature && showFilterToRequiresAttention && (
          <Checkbox
            checked={value.filterToRequiresClientAttention}
            label={
              <div className="d-flex flex-row">
                <AttentionTriangle className="my-auto mr-2" width="1.4em" />
                <FormattedMessage
                  id="components/recipes/RecipeQueryControls:filterToRequiresClientAttention"
                  defaultMessage="Show only {recipesLabel} that need attention"
                  values={{ recipesLabel: recipeLabel.pluralLowercase }}
                />
              </div>
            }
            onChange={(filterToRequiresClientAttention) =>
              onChange({ filterToRequiresClientAttention })
            }
          />
        )}
      </div>
    </div>
  );
}

interface RecipeSearchBoxProps {
  onChange: (value: string) => void;
  value: string;
}

function RecipeSearchBox(props: RecipeSearchBoxProps) {
  const { onChange, value } = props;

  const intl = useIntl();
  const recipeLabel = useRecipeLabel();

  const searchPlaceholder = intl.formatMessage(
    {
      id: "components/recipes/RecipeQueryControls:RecipeSearchBox/searchPlaceholder",
      defaultMessage: "Search {recipeLabel} & codes",
    },
    {
      recipeLabel: recipeLabel.pluralLowercase,
    }
  );

  return (
    <SearchBox
      onChange={onChange}
      placeholder={searchPlaceholder}
      value={value}
    />
  );
}

interface OrderByDropdownProps {
  onChange: (value: OrderBy) => void;
  value: OrderBy;
}

function OrderByDropdown(props: OrderByDropdownProps) {
  const { onChange, value } = props;

  const intl = useIntl();

  return (
    <Select<OrderBy>
      className="sort-by"
      isClearable={false}
      onChange={(orderBy) => onChange(orderBy!)}
      optionKey={(orderBy) => orderBy}
      options={orderByOptions}
      renderOption={(orderBy) =>
        intl.formatMessage(
          {
            id: "components/recipes/RecipeQueryControls:sortBy",
            defaultMessage: "Sort by: {order}",
          },
          { order: recipeOrderLabel(intl, orderBy) }
        )
      }
      dropdownArrow="upDown"
      value={value}
    />
  );
}

interface SelectCollectionProps {
  collections: CollectionToSelect[];
  placeholder: string;
  selectedCollection: CollectionToSelect | null;
  setSelectedCollection: (
    selectedCollection: CollectionToSelect | null
  ) => void;
}

type SelectAllCollections = { type: "allCollections" };
const SELECT_ALL_COLLECTIONS: SelectAllCollections = { type: "allCollections" };

type CollectionToSelectOrDefault =
  | (CollectionToSelect & { type: "collectionToSelect" })
  | SelectAllCollections;

function SelectCollection(props: SelectCollectionProps) {
  const {
    collections,
    placeholder,
    selectedCollection,
    setSelectedCollection,
  } = props;

  const collectionsWithDefault = [
    SELECT_ALL_COLLECTIONS,
    ...collections.map((collection) => {
      return {
        ...collection,
        type: "collectionToSelect",
      } as CollectionToSelect & { type: "collectionToSelect" };
    }),
  ];
  return (
    <Select<CollectionToSelectOrDefault>
      className="collection-select"
      isClearable={false}
      onChange={(newValue) =>
        newValue?.type === "collectionToSelect"
          ? setSelectedCollection(newValue)
          : setSelectedCollection(null)
      }
      optionKey={(selectedCollection) =>
        selectedCollection.type === "collectionToSelect"
          ? selectedCollection.id.toString()
          : placeholder
      }
      options={collectionsWithDefault}
      renderOption={(selectedCollection) =>
        selectedCollection.type === "collectionToSelect"
          ? selectedCollection.name
          : placeholder
      }
      dropdownArrow="upDown"
      value={
        selectedCollection
          ? { ...selectedCollection, type: "collectionToSelect" }
          : SELECT_ALL_COLLECTIONS
      }
    />
  );
}

SelectCollection.fragments = {
  collection: gql`
    fragment CollectionToSelect on RecipeCollection {
      id
      name
    }
  `,
};
