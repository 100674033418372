import gql from "graphql-tag";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import { useEffectTypeForImpactCategoryPerFunctionalUnit } from "../../domain/EffectType";
import { FunctionalUnit } from "../../domain/functionalUnits";
import { ImpactCategory } from "../../domain/impactCategories";
import {
  useDataQualityScore,
  useFoodManufacturerOrganization,
  useLandUse,
  useWaterUse,
} from "../../services/useOrganizationFeatures";
import { useTracking } from "../../tracking";
import DQSInformationModalTooltip from "../utils/DQS/DQSInformationModalTooltip";
import { Panel } from "../utils/Panel";
import {
  GhgEmissions,
  LandUse,
  WaterUse,
} from "../utils/Vectors/illustrations";
import FunctionalUnitDropdown from "./FunctionalUnitDropdown";
import ImpactCategoryCard from "./ImpactCategoryCard";
import { ImpactSummary_RecipeImpact as RecipeImpact } from "./ImpactSummary.graphql";

import "./ImpactSummary.css";

interface ImpactSummaryProps {
  impact: RecipeImpact;
}

export default function ImpactSummary(props: ImpactSummaryProps) {
  const { impact } = props;

  const hasFeatureDataQualityScore = useDataQualityScore();
  const foodManufacturerOrganization = useFoodManufacturerOrganization();
  const landUse = useLandUse();
  const waterUse = useWaterUse();
  const { trackFunctionalUnitSet } = useTracking();

  const [functionalUnit, setFunctionalUnit] = useState<FunctionalUnit>(
    FunctionalUnit.KG
  );

  const ghgEffectType = useEffectTypeForImpactCategoryPerFunctionalUnit(
    ImpactCategory.GHG,
    functionalUnit
  );
  const landUseEffectType = useEffectTypeForImpactCategoryPerFunctionalUnit(
    ImpactCategory.LAND_USE,
    functionalUnit
  );
  const waterUseEffectType = useEffectTypeForImpactCategoryPerFunctionalUnit(
    ImpactCategory.WATER_USE,
    functionalUnit
  );

  const ghgMagnitude = ghgEffectType.get(impact);
  const landUseMagnitude = landUseEffectType.get(impact);
  const waterUseMagnitude = waterUseEffectType.get(impact);

  const ghgCard = (
    <ImpactCategoryCard
      impactCategory={
        <FormattedMessage
          id="components/recipes/RecipeBreakdown:ghgEmissions"
          defaultMessage="GHG Emissions"
        />
      }
      impact={
        ghgMagnitude !== null
          ? {
              magnitude: ghgMagnitude,
              dataQualityScore: impact.dataQualityScore?.ghg ?? 0, // TODO: Fix when merged with non-null DQS branch
              unit: ghgEffectType.impactCategoryUnit,
            }
          : null
      }
      symbol={<GhgEmissions width={32} />}
    />
  );

  const landUseCard = (
    <ImpactCategoryCard
      impactCategory={
        <FormattedMessage
          id="components/recipes/RecipeBreakdown:landUse"
          defaultMessage="Land Use"
        />
      }
      impact={
        landUseMagnitude === null || impact.unavailableLandUse
          ? null
          : {
              magnitude: landUseMagnitude,
              dataQualityScore: impact.dataQualityScore?.land_use ?? 0, // TODO: Fix when merged with non-null DQS branch
              unit: landUseEffectType.impactCategoryUnit,
            }
      }
      symbol={<LandUse width={32} />}
    />
  );

  const waterUseCard = (
    <ImpactCategoryCard
      impactCategory={
        <FormattedMessage
          id="components/recipes/RecipeBreakdown:waterUse"
          defaultMessage="Water Use"
        />
      }
      impact={
        waterUseMagnitude === null || impact.unavailableWaterUse
          ? null
          : {
              magnitude: waterUseMagnitude,
              dataQualityScore: impact.dataQualityScore?.water_use ?? 0, // TODO: Fix when merged with non-null DQS branch
              unit: waterUseEffectType.impactCategoryUnit,
            }
      }
      symbol={<WaterUse width={32} />}
    />
  );

  const impactCategoryCards = [
    ghgCard,
    ...(landUse ? [landUseCard] : []),
    ...(waterUse ? [waterUseCard] : []),
  ];

  const handleFunctionalUnitChange = (functionalUnit: FunctionalUnit) => {
    setFunctionalUnit(functionalUnit);
    trackFunctionalUnitSet({
      chart: "Summary",
      functionalUnit,
      foodManufacturerOrganization,
    });
  };

  return (
    <Panel className="ImpactSummary_Panel">
      <div className="ImpactSummary__Header">
        <h3 className="my-auto">
          <FormattedMessage
            id="components/recipes/ImpactSummary:title"
            defaultMessage="Impact Summary"
          />
        </h3>
        <FunctionalUnitDropdown
          selectedFunctionalUnit={functionalUnit}
          onChange={handleFunctionalUnitChange}
        />
      </div>
      <div className="ImpactSummary__ImpactCategoryCardsContainer">
        {impactCategoryCards.map((card, index) => (
          <div
            className="ImpactSummary__ImpactCategoryCardContainer"
            key={index}
          >
            {card}
          </div>
        ))}
      </div>
      {hasFeatureDataQualityScore && (
        <div className="ImpactSummary_DQSTooltipLabelContainer">
          <DQSInformationModalTooltip />
          <div className="small">
            <FormattedMessage
              id="components/recipes/ImpactSummary:dqsTooltipLabel"
              defaultMessage="<b>DQS:</b> Data Quality Score"
              values={{
                b: (chunks: React.ReactNode) => (
                  <span className="medium-font">{chunks}</span>
                ),
              }}
            />
          </div>
        </div>
      )}
    </Panel>
  );
}

ImpactSummary.fragments = {
  recipeImpact: gql`
    fragment ImpactSummary_RecipeImpact on RecipeImpact {
      dataQualityScore {
        ghg
        land_use
        water_use
      }
      effects {
        ghgPerKg
        ghgPerRootRecipeServing
        landUsePerKg
        landUsePerRootRecipeServing
        waterUsePerKg
        waterUsePerRootRecipeServing
      }
      unavailableLandUse
      unavailableWaterUse
    }
  `,
};
