import { ImpactRating } from "../../__generated__/globalTypes";
import Card from "../utils/Card";
import RecipePageCarbonLabel from "./RecipePageCarbonLabel";

const stories = {
  name: "RecipePageCarbonLabel",
  render: () => (
    <Card className="w-25">
      <RecipePageCarbonLabel
        impact={{
          impactRating: ImpactRating.LOW,
          effects: { ghgPerKg: 10, ghgPerRootRecipeServing: 1.12 },
        }}
        recipe={{ id: -1, isHotDrink: false }}
        showExportButton={true}
      />
    </Card>
  ),
};

export default stories;
